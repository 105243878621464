import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { LocationDto } from 'appDtos';
import { Container, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { Loading } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';

export function Locations() {
  const [locations, setLocations] = useState<LocationDto[]>([]);
  const [loading, setLoading] = useState(true);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetLocations());
        if (response.data) {
          setLocations(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <PageHeader pageTitle='Locations' navPath={AppPaths.getCreateLocation()}/>
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        locations.length > 0 ? 
        <ListGroup numbered as="ol">
        {
          locations.map((location,i) => (
              <ListGroup.Item key={location.locationId} action as="li" className="d-flex justify-content-between align-items-start clicable" onClick={() => goTo(AppPaths.getLocationDetail(location.locationId))}>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{location.name}</div>
                </div>
              </ListGroup.Item>
            ))
        }
        </ListGroup>
        : (
          <AlertComponent message="No location has been created." variant="info"/>
        )
      )}
    </Container>)
}
