import { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { UserDto } from 'appDtos';
import { Container, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AlertComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { AppPaths } from '../../../shared/appConstants';
import { Loading } from '../../../shared/components';
import { formatDate } from '../../../shared/utils/utils';

export function Users() {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [loading, setLoading] = useState(true);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await API.get(AdministrationPaths.GetUsers());
        if (response.data) {
          setUsers(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    }

    if (!users.length) {
      fetch();
    }
  }, [API, users, setError]);

  return (
    <Container>
      <PageHeader pageTitle='Users' navPath={AppPaths.getCreateUser()}/>
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        users.length > 0 ? 
        <ListGroup as="ol">
           <ListGroup.Item key="top" className="d-flex justify-content-spaced-evenly align-items-start">
           <div className="ms-2 me-auto col-2 fw-bold">User</div>
           <div className="ms-2 me-auto col-2 fw-bold">Created Date</div>
           <div className="ms-2 me-auto col-2 fw-bold">Last Login Date</div>
           <div className="ms-2 me-auto col-2 fw-bold">Login Count</div>
           <div className="ms-2 me-auto col-2 fw-bold">Email Confirmed</div>
           <div className="ms-2 me-auto col-2 fw-bold">Status</div>
         </ListGroup.Item>
        {users.map((user,i) => (
              <ListGroup.Item key={i} action as="li" className="d-flex justify-content-spaced-evenly  align-items-start clicable" onClick={() => goTo(AppPaths.getUserDetail(user.id))}>
                <div className="ms-2 me-auto col-2">
                  <div className="">{user.firstName} {user.lastName}</div>
                  {user.email}
                </div>
                <div className="ms-2 me-auto col-2">
                  {formatDate(user.createdDate)}
                </div>
                <div className="ms-2 me-auto col-2">
                  {user.lastLoginDate ? formatDate(user.lastLoginDate): 'N/A'}
                </div>
                <div className="ms-2 me-auto col-2">
                  {user.loginsCount}
                </div>
                <div className="ms-2 me-auto col-2">
                  {user.emailConfirmed ? 'YES': 'NO'}
                </div>
                <div className="ms-2 me-auto col-2">
                  {user.isActive ? 'Active': 'Inactive'}
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>
        : (
          <AlertComponent message="No user has been created." variant="info"/>
        )
      )}
    </Container>
  );
}
