import { faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Required } from '../../shared/components';
import { Icon } from '../../shared/components';

export type FileType = {
    title: string;
    content: any;
}

type Props = {
    show: boolean;
    files: FileType[];
    handleModalAction: (file?: FileType) => void;
}

export function FileUploadModal({ show, files, handleModalAction }: Props) {
    const [file, setFile] = React.useState<FileType>({ title: '', content: '' });
    const [fileError, setFileError] = React.useState<FileType>({ title: '', content: '' });

    const handleTitleChange = (e: any) => {
        if (fileError.title) setFileError({ ...fileError, title: '' });
        setFile({ ...file, title: e.target.value });
    }

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];

        if (["jpeg", "jpg", "png", "pdf"].includes(selectedFile?.name.split('.').pop().toLowerCase())) {
            setFile({ ...file, content: selectedFile });
        } else {
            setFile({ ...file, content: '' });
            setFileError({ ...fileError, content: 'Unsupported file was selected. supported formats: "jpeg", "jpg", "png", "pdf".' });
        }
    }

    const save = () => {
        const error = { ...fileError };
        
        if (!file.title) error.title = 'Document title is required.';
        if (!file.content) error.content = 'No file selected.';
        if (files.some(f => f.title === file.title.trim())) {
            error.title = 'A document with the same title is already added.'
        }
        if (error.title || error.content) return setFileError(error);
        
        file.title = file.title.trim();
        handleModalAction(file);
        setFile({ ...file, title: '', content: '' });
    }

    const close = () => {
        if (file.title || file.content) setFile({ ...file, title: '', content: '' });
        if (fileError.title || fileError.content) setFileError({ ...fileError, title: '', content: '' });
        handleModalAction();
    };

    if (!show) {
        return null;
    }

    return (
        <Modal show={show} onHide={close} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title className="fw-bold">Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="title">
                    <Form.Label className="fw-bold">Document Title<Required /></Form.Label>
                    <Form.Control
                        type="text"
                        autoFocus
                        autoComplete="off"
                        placeholder="Enter document title."
                        value={file.title}
                        onChange={handleTitleChange}
                        isInvalid={Boolean(fileError.title)}
                    />
                    <Form.Control.Feedback type="invalid">{fileError.title}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group controlId="selectFile" className="my-3">
                    <Form.Label className="fw-bold">Select file<Required /></Form.Label>
                    <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        aria-label="select File"
                        isInvalid={Boolean(fileError.content)}
                    />
                    <Form.Control.Feedback type="invalid">{fileError.content}</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={close}>
                    {<Icon icon={faClose} />} Close
                </Button>
                <Button disabled={Boolean(fileError.title || fileError.content)} variant="success" onClick={save}>
                    <Icon icon={faSave} /> Save
                </Button>
            </Modal.Footer>
        </Modal >
    )
}