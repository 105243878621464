import { Alert, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../shared/components';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const Unauthorized = () => {
  const goBack = useNavigate();

  return (
    <Container className="mt-5">
      <Alert className="border-3" show={true} variant="danger">
        <Alert.Heading><Icon icon={faExclamationCircle} /> Access Denied!</Alert.Heading>
        <p>
          We're sorry, but it seems you don't have the necessary permissions to access this page.
        </p>
        <hr />
        <p className="fw-bold">Possible reasons for this error:</p>
        <ol>
          <li>
            <strong>Authentication Required: </strong><br/>You might need to log in to your account to access this content. Please make sure you are logged in and try again.
          </li>
          <li>
            <strong>Insufficient Permissions: </strong><br/>Your account may not have the required privileges to view this page. If you believe this is an error, please contact our support team for assistance.
          </li>
          <li>
            <strong>Expired Session: </strong><br/>Your session may have expired. Please try refreshing the page or logging in again.
          </li>
        </ol>

        <p>If you believe you should have access to this page or if you have any questions, please contact your system administrator.</p>
       
        <hr />
        <div className="d-flex justify-content-end fw-bold">
          <Button className="fw-bold btn-lg" onClick={() => goBack('/')} variant="outline-danger">
            Go to Home Page
          </Button>
        </div>
      </Alert>
    </Container>
  )
}
