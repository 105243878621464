import React, { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { isValidPassword } from '../../shared/utils/validators';
import { PasswordRules } from './PwRuless';
import { SpinnerComponent } from '../../shared/components';
import { Icon } from '../../shared/components';
import { faEye, faEyeSlash, faArrowCircleDown, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { AccountPaths } from '../../shared/api/constants';
import { PageHeader } from '../../shared/components';

export interface UserPasswordResetInfo {
  newPassword: string;
  confirmedPassword: string;
}

export function ResetPassword() {
  const [resetSuccessMessage, setResetSuccessMessage] = React.useState('');
  const [isExpired, setIsExpired] = React.useState<boolean | undefined>();
  const { errorMessage, setError } = useApiHandleError();
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);


  const [formErrors, setFormError] = useState<any>({});
  const goTo = useNavigate();
  const API = useApi();

  const [formData, setFormData] = useState<UserPasswordResetInfo>({
    newPassword: '',
    confirmedPassword: '',
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (formErrors[name]) setFormError({ ...formErrors, [name]: '', });
    if (errorMessage) setError('');

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    const post = async () => {
      const formErrors = { newPassword: '', confirmedPassword: '' };

      if (!formData.newPassword) formErrors.newPassword = 'New passsword is required.';
      else if (!isValidPassword(formData.newPassword)) formErrors.newPassword = 'New passsword does not meet password requirement.';

      if (isValidPassword(formData.newPassword) && formData.newPassword !== formData.confirmedPassword) formErrors.confirmedPassword = 'Confirm password does not match.';

      if (Object.values(formErrors).some(v => v)) {
        setLoading(false);
        return setFormError(formErrors);
      }

      try {
        const { email, token, currentPassword, isNewUser } = values;
        const data = {
          ...formData,
          currentPassword,
          email,
          token,
          isNewUser: isNewUser ? true : false
        }
        const res = await API.post(AccountPaths.ResetPassword(), data);
        setResetSuccessMessage(res.data.message);
        window.localStorage.removeItem(StorageKeys.APP_TOKEN);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    }

    post();
  };

  const values = React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }, []);

  React.useEffect(() => {
    if (values?.timeStamp) {
      const expired = Date.now() > Date.parse(values?.timeStamp);
      setIsExpired(expired);
    }
  }, [values]);

  if (isExpired === undefined) {
    return <Loading />;
  }

  const renderAlert = (val: { message: string, onClick?: () => void, btnText?: string, variant?: string, moreInfo?: string }) => {
    const v = val.variant ?? 'danger';
    return (
      <Alert className="border-3" show={true} variant={v}>
        <Alert.Heading className="h5"> {<Icon icon={val.variant ? faCheckCircle : faExclamationCircle} />} {val.message}</Alert.Heading>
        {Boolean(val.moreInfo) && <p>{val.moreInfo}</p>}
        {!!val.onClick && <hr />}
        {!!val.onClick && <div className="d-flex justify-content-end">
          <Button className="btn btn-md fw-bold" onClick={val.onClick} variant={`outline-${v}`}>
            {val.btnText}
          </Button>
        </div>}
      </Alert>);
  }

  const toggleShow = () => setShow(!show);

  const errorAlertValues = { message: errorMessage }
  const expiredAlertValues = { message: 'Oh snap! Your link is no longer valid.', moreInfo: 'Contact your system administrator to send new email confirmation link.' }
  const resetSuccessAlertValues = { message: 'Password successfully reset.', onClick: () => goTo('/'), btnText: 'Go to Login', variant: 'success' }

  return (
    <Container>
      <PageHeader pageTitle='Reset Password' />

      {Boolean(errorMessage) && renderAlert(errorAlertValues)}
      {isExpired && renderAlert(expiredAlertValues)}
      {Boolean(resetSuccessMessage) && renderAlert(resetSuccessAlertValues)}

      {!Boolean(isExpired || resetSuccessMessage) && <Form noValidate onSubmit={handleSubmit}>
        {/* New Password */}
        <Form.Group controlId="newPassword" className="mt-3">
          <Form.Label className="fw-bold">New Password</Form.Label>
          <Form.Control
            size="lg"
            type={show ? 'text' : 'password'}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            autoFocus
            isInvalid={Boolean(formErrors.newPassword)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.newPassword}</Form.Control.Feedback>
        </Form.Group>

        {/* Confirm password */}
        <Form.Group controlId="confirmedPassword" className="mt-3">
          <Form.Label className="fw-bold">Confirm Password</Form.Label>
          <Form.Control
            size="lg"
            type={show ? 'text' : 'password'}
            name="confirmedPassword"
            value={formData.confirmedPassword}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.confirmedPassword)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.confirmedPassword}</Form.Control.Feedback>
        </Form.Group>
        <Button onClick={toggleShow} variant="outline-success" className="col-1 mt-3 btn btn-sm fw-bold"> <Icon icon={show ? faEyeSlash : faEye} /> {`${show ? 'Hide' : 'Show'}`}</Button><br />
        <PasswordRules value={formData.newPassword} />
        <Button variant="success" type="submit" size='lg' className="fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faArrowCircleDown} />} Submit
        </Button>
      </Form>}
    </Container>
  );
}
