import { useState, useEffect } from "react";

export function useLocalStorage<T>(key: string, defaultValue: T){
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
        } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  const removeValue = (key:string) => localStorage.removeItem(key); 

  return [value, setValue, removeValue];
};

export const StorageKeys = {
  ADMIN_STORED_USERS: '__users__',
  APP_DATA: '__appData__',
  SEARCH_TABLE_VIEW: '__isSearchTabV__',
  REJECTIONS_TABLE_VIEW: '__isRejectionTabV__',
  PENDING_TABLE_VIEW: '__isPendingTabV__',
  APP_TOKEN: '__auth__',
  APP_CODES: '__appCodes__',
};

