import { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faEdit, faExclamationCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Container, Card, Button, Col, Row } from 'react-bootstrap'
import { AppPaths } from '../../../shared/appConstants'
import { AlertComponent } from '../../../shared/components'
import { BackButton } from '../../../shared/components'
import { Icon } from '../../../shared/components'
import { Loading } from '../../../shared/components'
import { CostCenterDetailDto } from 'appDtos'
import { useParams, useNavigate } from 'react-router-dom'
import { AdministrationPaths } from '../../../shared/api/constants'
import { useApi } from '../../../shared/hooks/useApi'
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError'
import { formatDate } from '../../../shared/utils/utils'
import { ConfirmationModal } from '../../../shared/components'

export function CostCenterDetail() {
  const { id } = useParams();
  const [costCenterDetail, setCostCenterDetail] = useState<CostCenterDetailDto | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetCostCenterDetail(Number(id)));
        if (response.data) {
          setCostCenterDetail(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteConfirmation(false);

    (async () => {
      try {
        await API.delete(AdministrationPaths.DeleteCostCenters(Number(id)));
        goTo(AppPaths.getCostCenters())
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    })();
  };

  const deleteModalContent = (
    <div>
      <p>Are you sure you want to delete the <strong>{`${costCenterDetail?.name}`}</strong> cost center?</p>
      <p className="text-danger"> <Icon icon={faExclamationCircle} /> <strong>Important: </strong> This action cannot be undone.</p>
      <p>This action will permanently remove the selected item from the system. Any associated data or records may also be deleted.</p>
      <p>Please confirm your choice below:</p>
    </div>
  )

  return (
    <Container>
      <PageHeader pageTitle='Cost Center Detail' />
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        costCenterDetail ? (
          <Card className="border border-1 border-success">
            <Card.Header>
              <Card.Title className='display-5 fw-bold'>{costCenterDetail.name}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Created Date</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(costCenterDetail.createdDate)}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Created By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{costCenterDetail.createdBy ?? 'System'}</Card.Title>
                </Col>
                {Boolean(costCenterDetail.lastUpdatedDate) && <Col md="3">
                  <Card.Subtitle className="mt-3">Last Updated</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(costCenterDetail.lastUpdatedDate)}</Card.Title>
                </Col>}
                {Boolean(costCenterDetail.lastUpdatedBy) && <Col md="3">
                  <Card.Subtitle className="mt-3">Last Updated By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{costCenterDetail.lastUpdatedBy}</Card.Title>
                </Col>}
              </Row>
            </Card.Body>
          </Card>
        ) : null
      )}
      {!loading && <>
        <BackButton />
        <Button onClick={() => goTo(AppPaths.getEditCostCenter(Number(costCenterDetail?.costCenterId)))} variant="success" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faEdit} />} Edit
        </Button>
        <Button onClick={() => setShowDeleteConfirmation(true)} variant="danger" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faTrashCan} />} Delete
        </Button>
      </>}
      <ConfirmationModal
        message={deleteModalContent}
        okHandler={handleDelete}
        cancelHandler={() => setShowDeleteConfirmation(false)}
        show={showDeleteConfirmation}
      />
    </Container>
  )
}
