import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../hooks/useApi';
import { AdministrationPaths } from '../api/constants';
import { useApiHandleError } from '../hooks/useApiHandleError';
import { Button, Form, ListGroup } from 'react-bootstrap';
import { RoleDto } from 'appDtos';
import { AlertComponent } from './Alert';
import { Icon } from './Icon';
import { faArrowCircleDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { Loading } from './Loading';
import { SpinnerComponent } from './Spinner';

type Props = {
  userId: string | undefined;
  show: boolean;
  userRoles: RoleDto[];
  update: (values: {show: boolean; fetchDetail: boolean}) => void;
};

export function RoleUpdateModal({ update, userId, show, userRoles }: Props) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [roles, setRoles] = useState<{ name: string; isSelected: boolean; }[]>([]);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => {
    let fetchDetail = false;
    if (successMessage) fetchDetail = true;
    setRoles([]);
    setSuccessMessage('');
    update({ show: false, fetchDetail });
  };

  useEffect(() => {
    if (show && !roles.length) {
      const fetch = async () => {
        const res = await API.get<RoleDto[]>(AdministrationPaths.GetRoles());
        setRoles(res.data.map(r => ({
          name: r.name,
          isSelected: userRoles.some(u => u.name === r.name),
        })));
        setLoading(false);
      };

      fetch();
    }
  }, [API, roles.length, show, userRoles]);

  const handleClick = (event: any) => {
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setError('');
    const selected = event.target.value;
    setRoles(roles.map(r => ({
      ...r,
      isSelected: r.name === selected ? (r.isSelected ? false : true) : false
    })));
  };

  const handleSubmit = () => {
    setSaving(true);
    const post = async () => {
      try {
        const res = await API.post(AdministrationPaths.UpdateUserRole(), { userId, userRoles: roles });
        setSuccessMessage(res.data.message);
      } catch (error) {
        setError(error);
      }
      setSaving(false);
    };

    post();
  };

  const userSelectedRole = userRoles[0]?.name;
  const isEmpty = userRoles.length === 0 && !roles.some(r => r.isSelected);
  const pendingChange = roles.some(r => r.isSelected && r.name === userSelectedRole);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Update User Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertComponent variant={successMessage ? 'success' : 'danger'} message={errorMessage || successMessage} />
        {loading ? <Loading/> : <ListGroup>
          {roles.map(r => (
            <ListGroup.Item className="d-flex justify-content-between fw-bold" key={r.name}>
              <span>{r.name}</span>
              <Form.Check onChange={handleClick} value={r.name} checked={r.isSelected} type="checkbox" aria-label={r.name} />
            </ListGroup.Item>
          ))}
        </ListGroup>}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} variant="secondary" onClick={handleClose}>
          {<Icon icon={faClose} />} Close
        </Button>
        <Button disabled={isEmpty || pendingChange || saving || loading || Boolean(successMessage || errorMessage)} variant="success" onClick={handleSubmit}>
          {saving ? <SpinnerComponent /> : <Icon icon={faArrowCircleDown} />} Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
