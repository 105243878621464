import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { AssetDto, RejectedAssetDto, Transaction } from 'appDtos';
import { formatDate, mapColor } from '../../shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from '../../shared/appConstants';

export const AssetResultCard = ({ asset }: { asset: AssetDto; }) => {
  const goTo = useNavigate();

  return (
    <Card
      onClick={() => goTo(AppPaths.getAssetDetail(asset.assetId))}
      className="mb-1 asset-card"
    >
      <Card.Header>
        <Card.Title className="fw-bold mb-0">{asset.name}</Card.Title>
        <Card.Subtitle>{asset.description}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md="3">
            <div className="mt-1">Created Date</div>
            <Card.Subtitle className="fw-bold">{formatDate(asset.createdDate)}</Card.Subtitle>
          </Col>
          <Col md="3">
            <div className="mt-1">Code</div>
            <Card.Subtitle className="fw-bold">{asset.code ?? 'n/a'}</Card.Subtitle>
          </Col>
          <Col md="3">
            <div className="mt-1">Serial Number</div>
            <Card.Subtitle className="fw-bold">{asset.serialNumber ?? 'n/a'}</Card.Subtitle>
          </Col>
          <Col md="3">
            <div className="mt-1">Status</div>
            <Card.Subtitle className={mapColor(asset.status ?? '')}>{asset.status}</Card.Subtitle>
          </Col>
          <Col md="3">
            <div className="mt-1">Condition</div>
            <Card.Subtitle className="fw-bold">{asset.condition}</Card.Subtitle>
          </Col>
          <Col md="3">
            <div className="mt-1">Caterory</div>
            <Card.Subtitle className="fw-bold">{asset.category}</Card.Subtitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export function AssetResultTable({ assets }: { assets: AssetDto[]; }) {
  const goTo = useNavigate();
  return (
    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Created Date</th>
            <th>Code</th>
            <th>Serial Number</th>
            <th>Status</th>
            <th>Condition</th>
            <th>Caterory</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset, i) => (
            <tr
              key={asset.assetId}
              onClick={() => goTo(AppPaths.getAssetDetail(asset.assetId))}
              className="asset-card"
            >
              <td>{i + 1}.</td>
              <td>{asset.name}</td>
              <td>{asset.description}</td>
              <td>{formatDate(asset.createdDate)}</td>
              <td>{asset.code ?? 'n/a'}</td>
              <td>{asset.serialNumber ?? 'n/a'}</td>
              <td className={mapColor(asset.status ?? '')}>{asset.status}</td>
              <td>{asset.condition}</td>
              <td>{asset.category}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export function RejectedAssetResultTable({ assets }: { assets: RejectedAssetDto[]; }) {
  const goTo = useNavigate();
  return (
    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Created Date</th>
            <th>Rejected Date</th>
            <th>Rejected By</th>
            <th>Rejection Reason</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset, i) => (
            <tr
              key={asset.assetId}
              onClick={() => goTo(AppPaths.getAssetDetail(asset.assetId))}
              className="asset-card"
            >
              <td>{i + 1}.</td>
              <td>{asset.name}</td>
              <td>{formatDate(asset.createdDate)}</td>
              <td>{formatDate(asset.rejectedDate)}</td>
              <td>{asset.rejectedBy}</td>
              <td>{asset.rejectionReason}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export function PendingReviewResultTable({ transactions }: { transactions: Transaction[]; }) {
  const goTo = useNavigate();
  return (
    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Created Date</th>
            <th>Created By</th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, i) => (
            <tr
              key={transaction.assetTransactionId}
              onClick={() => goTo(AppPaths.getAssetDetail(transaction.assetId))}
              className="asset-card"
            >
              <td>{i + 1}.</td>
              <td>{transaction.assetName}</td>
              <td>{formatDate(transaction.date)}</td>
              <td>{transaction.createdBy}</td>
              <td>{transaction.type}</td>
              <td>{transaction.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
