import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DateRange {
    from: Date | null;
    to: Date | null;
}

export interface StringDateRange {
    from: string | null;
    to: string | null;
}

interface DatePickerProps {
    dateRange: DateRange;
    setDate: (dateRange: DateRange) => void;
}

export function DatePickerComponent({ dateRange, setDate }: DatePickerProps) {
    const CustomInput = forwardRef((props: any, ref: any) => {
        return (
            <Form.Control
                size="sm"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                ref={ref}
                placeholder="Select purchased date"
            />
        )

    });
    return (
        <div className="d-flex justify-content-start">
            <div className="d-flex justify-content-between">
                <div className="mx-1 fw-bold">From:</div>
                <DatePicker
                    isClearable={Boolean(dateRange.from)}
                    selected={dateRange.from}
                    onChange={(date: Date | null) => setDate({ ...dateRange, from: date })}
                    selectsStart
                    customInput={<CustomInput />}
                    startDate={dateRange.from}
                    maxDate={dateRange.to ?? new Date()}
                    placeholderText="Select start date." />
            </div>
            <div className="d-flex justify-content-between">
                <div className="mx-1 fw-bold">To:</div>
                <DatePicker
                    isClearable={Boolean(dateRange.to)}
                    selected={dateRange.to}
                    customInput={<CustomInput />}
                    onChange={(date: Date | null) => setDate({ ...dateRange, to: date })}
                    selectsEnd
                    startDate={dateRange.from}
                    endDate={dateRange.to}
                    minDate={dateRange.from}
                    maxDate={new Date()}
                    placeholderText="Select end date." />
            </div>
        </div>
    );
}

export function SingleDatePickerComponent({ date, setDate }: { date: Date | null, setDate: (date: Date | null) => void }) {
    const CustomInput = forwardRef((props: any, ref: any) => {
        return (
            <Form.Control
                size="lg"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                ref={ref}
                placeholder="Select purchased date"
            />
        )

    });

    return <DatePicker
        isClearable={Boolean(date)}
        customInput={<CustomInput />}
        selected={date}
        maxDate={new Date()}
        onChange={(date: Date | null) => setDate(date)}
    />
}
