import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../shared/hooks/useAuth';

export const AuthenticatedProtect = () => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (isAuthenticated()) {
        return <Outlet />;
    }
    window.localStorage.setItem('redPath', location.pathname);
    return <Navigate to="/" state={{ from: location }} replace />
}

export const AuthorizedProtect = (props: { roles: string[] }) => {
    const { isAuthenticated, isInRole } = useAuth() ?? {};
    const location = useLocation();

    if (!isAuthenticated()) {
        return <Navigate to="/" state={{ from: location }} replace />
    }

    for (const role of props.roles) {
        if (isInRole(role)) {
            return <Outlet />;
        }
    }

    return <Navigate to="/unauthorized" state={{ from: location }} replace />
}