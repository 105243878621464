import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../shared/components';
import { useNavigate, useParams } from 'react-router';
import { useApi } from '../../../shared/hooks/useApi';
import { AccountPaths, AdministrationPaths } from '../../../shared/api/constants';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { UserDetailDto } from 'appDtos';
import { AlertComponent } from '../../../shared/components';
import { formatDate, formatPhoneNumber } from '../../../shared/utils/utils';
import { AppPaths } from '../../../shared/appConstants';
import { Icon } from '../../../shared/components';
import { faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { RoleUpdateModal } from '../../../shared/components';
import { Loading } from '../../../shared/components';
import { BackButton } from '../../../shared/components';
import { SpinnerComponent } from '../../../shared/components';

export function UserDetail() {
  const { id } = useParams();
  const [userDetail, setUserDetail] = useState<UserDetailDto | null>(null);
  const [loading, setLoading] = useState(true);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();
  const [updateValues, setUpdateValues] = useState({ show: false, fetchDetail: false });
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetUserDetail(id as string));
        if (response.data) {
          setUserDetail(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (updateValues.fetchDetail) {
        try {
          const response = await API.get(AdministrationPaths.GetUserDetail(id as string));
          if (response.data) {
            setUserDetail(response.data);
            setUpdateValues({ ...updateValues, fetchDetail: false })
          }
        } catch (error) {
          setError(error)
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValues.fetchDetail, updateValues]);

  const update = (values: any) => {
    setUpdateValues({ ...updateValues, ...values });
  }

  const handleSend = () => {
    setSending(true);
    (async () => {
      try {
        const res = await API.post(AccountPaths.SendUserAccountActivationRequest(), { userId: userDetail?.id });
        setSuccessMessage(res.data.message);
      } catch (error) {
        setError(error);
      }
      setSending(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='User Detail' />
      {loading ? (
        <Loading />
      ) : (
        userDetail ? (
          <Card className="border border-1 border-success">
            <Card.Header>
              <AlertComponent variant={successMessage ? 'success' : 'danger'} message={errorMessage || successMessage} />
              <Row>
                <Col md="4">
                  <div className="avaitor rounded-circle bg-success text-light display-4 text-center p-5 m-4 fw-bold">
                    {userDetail.firstName[0]}{userDetail.lastName[0]}
                  </div>
                </Col>
                <Col>
                  <Card.Title className='display-5 fw-bold mt-5'>{userDetail.firstName} {userDetail.lastName}</Card.Title>
                  <div className="d-flex justify-content-between">
                    <Card.Title className="h1 fw-bold">Email: {userDetail.email} </Card.Title>
                    <Button disabled={sending} onClick={handleSend} variant="success" size='sm' className="fw-bold">{sending ? <SpinnerComponent /> : <Icon icon={faEnvelope} />} Send Email Confirmation</Button>
                  </div>
                  <Card.Title className="h1 fw-bold">Phone: {userDetail.phoneNumber ? formatPhoneNumber(userDetail.phoneNumber) : 'N/A'}</Card.Title>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="4">
                  <Card.Subtitle className="mt-3">Status</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{userDetail.isActive ? 'Active' : 'Inactive'}</Card.Title>
                </Col>
                <Col md="4">
                  <Card.Subtitle className="mt-3">Created Date</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(userDetail.createdDate)}</Card.Title>
                </Col>
                <Col md="4">
                  <Card.Subtitle className="mt-3">Created By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{userDetail.createdBy ?? 'System'}</Card.Title>
                </Col>
                <Col md="4">
                  <Card.Subtitle className="mt-3">Login Count</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{userDetail.loginsCount}</Card.Title>
                </Col>
                <Col md="4">
                  <Card.Subtitle className="mt-3">Last Login Date/Time</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{userDetail.lastLoginDate ? new Date(userDetail.lastLoginDate).toLocaleString() : 'N/A'}</Card.Title>
                </Col>
                {Boolean(userDetail.lastUpdatedDate) && <Col md="4">
                  <Card.Subtitle className="mt-3">Last Updated</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(userDetail.lastUpdatedDate)}</Card.Title>
                </Col>}
                {Boolean(userDetail.lastUpdatedBy) && <Col md="4">
                  <Card.Subtitle className="mt-3">Last Updated By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{userDetail.lastUpdatedBy}</Card.Title>
                </Col>}
              </Row>
              <div className="my-3 border-2 p-2 rounded border border-2 d-flex justify-content-between">
                <Card.Title className="fw-bold mb-0">ROLES</Card.Title>
                <Button onClick={() => update({ show: true })} variant="success" size='sm' className="fw-bold">{<Icon icon={faEdit} />} Update Role</Button>
              </div>
              <Card>
                <ListGroup variant="flush">
                  {userDetail.roles.length ? userDetail.roles.map(r => <ListGroup.Item className="fw-bold" key={r.roleId}>{r.name}</ListGroup.Item>) : <ListGroup.Item>User has no role added.</ListGroup.Item>}
                </ListGroup>
              </Card>
            </Card.Body>
          </Card>
        ) : (
          <AlertComponent message={errorMessage} />
        )
      )}
      {!loading && <>
        <BackButton />
        <Button onClick={() => goTo(AppPaths.getEditUser(userDetail?.id))} variant="success" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faEdit} />} Edit
        </Button>
      </>}
      <RoleUpdateModal userId={userDetail?.id} show={updateValues.show} update={update} userRoles={userDetail?.roles ?? []} />
    </Container>
  );
}
