import { Alert, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../shared/components';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const PageNotFound = () => {
  const goBack = useNavigate();

  return (
    <Container className="mt-5">
      <Alert className="border-3" show={true} variant="danger">
        <Alert.Heading><Icon icon={faExclamationCircle} /> 404 - Page Not Found!</Alert.Heading>
        <p>
        Oops! It seems like the page you're looking for does not exist. 
        </p>
        <hr />
        <div className="d-flex justify-content-end fw-bold">
          <Button className="fw-bold btn-lg" onClick={() => goBack('/')} variant="outline-danger">
            Go to Home Page
          </Button>
        </div>
      </Alert>
    </Container>
  )
}
