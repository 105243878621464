import { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { BackButton } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { SpinnerComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { Required } from '../../../shared/components';

export function EditCategory() {
  const { id } = useParams();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const goTo = useNavigate();
  const API = useApi();
  const [category, setCategory] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetCategoryDetail(Number(id)));
        if (response.data) {
          setCategory(response.data.name);
        }
      } catch (error) {
        setError(error)
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event: any) => {
    if (formError) setFormError('');
    if (errorMessage) setError('');

    setCategory(event.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!category) return setFormError('Category is required.');

    setIsLoading(true);
    (async () => {
      try {
        const categoryId = Number(id);
        await API.put(AdministrationPaths.UpdateCategory(), { categoryId, name: category });
        goTo(AppPaths.getCategoryDetail(categoryId))
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='Edit Category' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Category Name<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="category"
            autoComplete="off"
            value={category}
            onChange={handleInputChange}
            autoFocus
            isInvalid={Boolean(formError)}
          />
          <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
        </Form.Group>

        <BackButton />
        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>
    </Container>
  );
}
