import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Icon(props: { className?: string, color?: string, size?: 'lg' | 'sm', icon: IconDefinition, placment?: string }) {
  let marginClass = `mr-2 ${props.className ? props.className : ''}`;
  if (props.placment === 'right') marginClass = 'mx-2'

  return props.color ?
    <span><FontAwesomeIcon color={props.color} className={marginClass} size={props.size ?? 'lg'} icon={props.icon} /></span>
    :
    <span><FontAwesomeIcon className={marginClass} size={props.size ?? 'lg'} icon={props.icon} /></span>;
}