import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from '../../shared/components';

export type DisposalModalProps = {
  show: boolean;
  disableButton: boolean;
  body: string | JSX.Element | undefined;
  okHandler: (() => void) | undefined;
  cancelHandler?: () => void;
};

export function DisposalModal({ disableButton, okHandler, cancelHandler, show, body }: DisposalModalProps) {
  const ok = () => {
    if (okHandler) {
      okHandler();
    }
  };
  return (
    <Modal show={show} onHide={cancelHandler} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="border-3 border-buttom border-dark bg-success text-light">
        <Modal.Title className="fw-bold">Please Confirm!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelHandler} className="fw-bold">
          {<Icon icon={faClose} />} Cancel
        </Button>
        <Button disabled={disableButton} variant="success" onClick={ok} className="fw-bold">
          <Icon icon={faCheck} /> Dispose
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
