import { useEffect, useState } from 'react'
import { PageHeader } from '../../shared/components'
import { faCheck, faTrashCan, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Filters } from 'appDtos';
import { Container, Button, Form, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AssetPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { Icon } from '../../shared/components';
import { Required } from '../../shared/components';
import { SpinnerComponent } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AppPaths } from '../../shared/appConstants';
import { filterSorter } from '../../shared/utils/utils';
import { BackButton } from '../../shared/components';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { FileType, FileUploadModal } from './FileUploadModal';
import { Loading } from '../../shared/components';
import { SingleDatePickerComponent } from '../../shared/components/DateRangePicker';


export type Data = {
  name: string;
  description: string;
  serialNumber: string;
  code: string;
  condition: string;
  category: string;
  custodian: string;
  purchasedDate: Date | null;
  location: string;
  costCenter: string;
  supplier: string;
  files: FileType[]
}

export function RegisterAsset() {
  const [filters, setFilters] = useState<Filters>(() => {
    const values = localStorage.getItem(StorageKeys.APP_DATA);
    return values ? JSON.parse(values) : null;
  });
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [asset, setAsset] = useState<Data>({
    name: '',
    description: '',
    serialNumber: '',
    condition: '',
    code: '',
    category: '',
    custodian: '',
    location: '',
    costCenter: '',
    supplier: '',
    purchasedDate: null,
    files: []
  });
  const [formErrors, setFormError] = useState({
    name: '',
    description: '',
    serialNumber: '',
    code: '',
    condition: '',
    category: '',
    custodian: '',
    location: '',
    costCenter: '',
    supplier: '',
    purchasedDate: '',
    files: ''
  });

  const goTo = useNavigate();
  const API = useApi();

  useEffect(() => {
    if (!filters) {
      setIsLoading(true);
      (async () => {
        try {
          const res = await API.get(AssetPaths.GetFilters());
          localStorage.setItem(StorageKeys.APP_DATA, JSON.stringify(res.data));
          setFilters(res.data);
        } catch (error) {
          setError(error);
        }
        setIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (formErrors[name as keyof Data]) setFormError({ ...formErrors, [name]: '' });
    if (errorMessage) setError('');

    setAsset({ ...asset, [name]: value });
  };

  const handleModalAction = (file?: FileType) => {
    if (formErrors.files) setFormError({ ...formErrors, files: '' });
    if (file) setAsset({ ...asset, files: [file, ...asset.files] });
    setShowFileUploadModal(false);
  }

  const removeFile = (title: string) => {
    setAsset({
      ...asset,
      files: asset.files.filter(f => f.title !== title),
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { files, name, description, purchasedDate, code, serialNumber, condition, category, location, costCenter, supplier, custodian } = asset;

    const categoryId = filters?.categories.find(f => f.value.trim() === category.trim())?.key;
    const locationId = filters?.locations.find(f => f.value.trim() === location.trim())?.key;
    const costCenterId = filters?.costCenters.find(f => f.value.trim() === costCenter.trim())?.key;
    const custodianId = filters?.custodians.find(f => f.value.trim() === custodian.trim())?.key;
    const supplierId = filters?.suppliers.find(f => f.value.trim() === supplier.trim())?.key;

    const errors = { ...formErrors };

    if (!name) errors.name = 'Asset name is required.';
    if (!description) errors.description = 'Asset Description is required.';
    if (!serialNumber) errors.serialNumber = 'Serial Number is required.';
    if (!code) errors.code = 'Asset Code is required.';
    if (!condition) errors.condition = 'Asset Condition is required.';
    if (!categoryId) errors.category = 'Asset Category is required.';
    if (!locationId) errors.location = 'Asset Location is required.';
    if (!custodianId) errors.custodian = 'Asset Custodian is required.';
    if (!files.length) errors.files = 'Asset Document is required.';

    if (Object.values(errors).some(v => v)) {
      return setFormError(errors);
    }

    setIsLoading(true);
    (async () => {
      try {
        const formData = new FormData();
        formData.append('name', name)
        formData.append('description', description)
        formData.append('code', code)
        purchasedDate && formData.append('purchasedDate', purchasedDate.toISOString())
        formData.append('serialNumber', serialNumber)
        formData.append('condition', filters?.conditions.find(f => f.value.trim() === condition.trim())?.key.toString() ?? '0')
        formData.append('categoryId', categoryId?.toString() ?? '0')
        formData.append('locationId', locationId?.toString() ?? '0')
        formData.append('costCenterId', costCenterId?.toString() ?? '0')
        formData.append('supplierId', supplierId?.toString() ?? '0')
        formData.append('custodianId', custodianId?.toString() ?? '0')

        files.forEach((file, index) => {
          formData.append(`documents[${index}].title`, file.title);
          formData.append(`documents[${index}].file`, file.content);
        });

        await API.post(AssetPaths.Register(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        return goTo(AppPaths.getHome());
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  const sortedConditions = filters?.conditions.filter(c => ['Good', 'Damaged', 'Fair'].includes(c.value)).sort(filterSorter) ?? [];
  const sortedLocations = filters?.locations.sort(filterSorter) ?? [];
  const sortedCustodians = filters?.custodians.sort(filterSorter) ?? [];
  const sortedCostCenters = filters?.costCenters.sort(filterSorter) ?? [];
  const sortedCategories = filters?.categories.sort(filterSorter) ?? [];
  const sortedSuppliers = filters?.suppliers.sort(filterSorter) ?? [];

  return (
    <Container className="mb-3">
      <PageHeader pageTitle='Register New Asset' />
      <AlertComponent message={errorMessage} />
      {loading && <Loading />}
      {!loading && <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Asset Name<Required /></Form.Label>
          <Form.Control
            autoFocus
            size="lg"
            type="text"
            name="name"
            placeholder="Enter asset name."
            value={asset.name}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.name)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="description" className="mt-3">
          <Form.Label className="fw-bold">Description<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="description"
            placeholder="Enter asset description."
            value={asset.description}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.description)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="code" className="mt-3">
          <Form.Label className="fw-bold">Code<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="code"
            placeholder="Enter asset code."
            value={asset.code}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.code)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.code}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="serialNumber" className="mt-3">
          <Form.Label className="fw-bold">Serial Number<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="serialNumber"
            placeholder="Enter asset serial number."
            value={asset.serialNumber}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.serialNumber)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.serialNumber}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="categoryId" className="mt-3">
          <Form.Label className="fw-bold">Category<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.category)} size="lg" value={asset.category} name="category" onChange={handleInputChange}>
            <option>Select Category</option>
            {sortedCategories.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.category}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="condition" className="mt-3">
          <Form.Label className="fw-bold">Condition<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.condition)} size="lg" value={asset.condition} name="condition" onChange={handleInputChange}>
            <option>Select Condition</option>
            {sortedConditions.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.condition}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="locationId" className="mt-3">
          <Form.Label className="fw-bold">Location<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.location)} size="lg" value={asset.location} name="location" onChange={handleInputChange}>
            <option>Select Location</option>
            {sortedLocations.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.location}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="custodian" className="mt-3">
          <Form.Label className="fw-bold">Custodian<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.custodian)} size="lg" value={asset.custodian} name="custodian" onChange={handleInputChange}>
            <option>Select custodian</option>
            {sortedCustodians.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.custodian}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="costCenterId" className="mt-3">
          <Form.Label className="fw-bold">Cost Center</Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.costCenter)} size="lg" value={asset.costCenter} name="costCenter" onChange={handleInputChange}>
            <option>Select Cost Center</option>
            {sortedCostCenters.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.costCenter}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="supplierId" className="mt-3">
          <Form.Label className="fw-bold">Supplier</Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.supplier)} size="lg" value={asset.supplier} name="supplier" onChange={handleInputChange}>
            <option>Select supplier</option>
            {sortedSuppliers.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.supplier}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="code" className="mt-3">
          <Form.Label className="fw-bold">Purchased Date</Form.Label><br/>
          <SingleDatePickerComponent date={asset.purchasedDate} setDate={(date) => setAsset({ ...asset, purchasedDate: date })} />
        </Form.Group>

        <div className="d-flex justify-content-between my-3">
          <Form.Label className="fw-bold">Documents</Form.Label>
          <Button className="fw-bold" variant="outline-success" onClick={() => setShowFileUploadModal(true)}>
            <Icon icon={faUpload} /> Add Document
          </Button>
        </div>
        {asset.files.length ?
          <ListGroup>
            <ListGroup.Item key="header" as="li" className="d-flex justify-space-between align-items-baseline transaction-card">
              <div className="col-1 fw-bold">#</div>
              <div className="col fw-bold">Document Title</div>
              <div className="col fw-bold">File</div>
              <div className="col-1 fw-bold">Action</div>
            </ListGroup.Item>
            {asset.files?.map((f, i) => (
              <ListGroup.Item key={f.title} as="li" className="d-flex justify-space-between align-items-start transaction-card">
                <div className="col-1">{i + 1}</div>
                <div className="col">{f.title}</div>
                <div className="col">{f.content.name}</div>
                <div className="col-1"> <Button onClick={() => removeFile(f.title)} variant="outline-danger" className="btn btn-sm fw-bold"><Icon icon={faTrashCan} /> Remove</Button></div>
              </ListGroup.Item>
            ))}
          </ListGroup> : (Boolean(formErrors.files) ? <p className="text-danger">{formErrors.files}</p> : <p>No document has been selected.</p>)}

        <BackButton />

        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>}
      <FileUploadModal show={showFileUploadModal} files={asset.files} handleModalAction={handleModalAction} />
    </Container>
  );
}
