import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Icon } from './Icon';
import { faCheck, faClose, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
  show: boolean;
  message: string | JSX.Element;
  okHandler: () => void;
  cancelHandler?: () => void;
};

export function ConfirmationModal({ okHandler, cancelHandler, show, message }: Props) {
  return (
    <Modal show={show} onHide={cancelHandler} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="border-3 border-buttom border-dark bg-success text-light">
        <Modal.Title className="fw-bold">Please Confirm!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelHandler} className="fw-bold">
          {<Icon icon={faClose} />} Cancel
        </Button>
        <Button variant="success" onClick={okHandler} className="fw-bold">
          <Icon icon={faCheck} /> Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export function RevertApprovalModal({ okHandler, cancelHandler, show }: {show: boolean, okHandler: () => void, cancelHandler?: () => void}) {
  return (
    <Modal show={show} onHide={cancelHandler} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="border-3 border-buttom border-dark bg-success text-light">
        <Modal.Title className="fw-bold">Please Confirm!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to revert this approver?</p>
        <p className="text-danger fw-bold"> <Icon icon={faExclamationCircle} /> <strong>Important: </strong> This action will set this asset tranaction back to "Pending Review".</p>
        <p>Please confirm your choice below:</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelHandler} className="fw-bold">
          {<Icon icon={faClose} />} Cancel
        </Button>
        <Button variant="success" onClick={okHandler} className="fw-bold">
          <Icon icon={faCheck} /> Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
