import React from "react";
import jwtDecode from 'jwt-decode';
import {useLocalStorage, StorageKeys } from "../hooks/useLocalStorage";
import { ILoginResponse, IAuthCodes, StringBoolObj, StringStringObj } from "appDtos";
import { SettingPaths } from "../api/constants";
import { useApi } from "../hooks/useApi";
// import API from '../api/api';

export interface CurrentUser {
    userId?: string;
    fullName?: string;
    roles?: number[];
    rCodes: StringBoolObj
    isInternal: boolean;
}

export interface Auth {
    token?: string;
    expiration?: string;
    isAuthenticated: boolean;
}

interface AuthContextType {
    setUpAuth: (loginResponse?: ILoginResponse) => void;
    getUser: () => CurrentUser | null
    isAuthenticated: () => boolean,
    isInRole: (key?: string) => boolean;
    getToken: () => string,
    rCodes: StringStringObj | null;
}

export const AuthContext = React.createContext<AuthContextType>({
    setUpAuth: (loginResponse?: ILoginResponse) => { },
    isAuthenticated: () => false,
    isInRole: (key?: string) => false,
    getUser: () => null,
    getToken: () => '',
    rCodes: null,
});

export const AuthProvider = ({ children }: any) => {
    const API = useApi();
    const [fromLocal, setToLocal] = useLocalStorage(StorageKeys.APP_TOKEN, null);
    const [codes, setCodes] = React.useState<IAuthCodes>(() => {
        const appCodes = localStorage.getItem(StorageKeys.APP_CODES);
        return appCodes ? JSON.parse(appCodes) : { rCodes: null };
    });

    React.useEffect(() => {
        if (!codes.rCodes) {
            const fetch = async () => {
                try {
                    const result = await API.get<IAuthCodes>(SettingPaths.GetAppCodes());
                    localStorage.setItem(StorageKeys.APP_CODES, JSON.stringify(result.data));
                    setCodes(result.data);
                } catch (error) {
                    console.log(error)
                }
            };
            fetch();
        }
     // eslint-disable-next-line
    }, []);

    const { token, expiration } = fromLocal ?? {};

    const setUpAuth = React.useCallback((loginResponse?: ILoginResponse) => {
        if (loginResponse?.token) {
            localStorage.setItem(StorageKeys.APP_TOKEN, JSON.stringify(loginResponse));
            setToLocal(loginResponse);
        } else {
            setToLocal(null);
            window.location.reload();
            localStorage.removeItem(StorageKeys.APP_TOKEN);
            localStorage.removeItem(StorageKeys.APP_CODES);
            localStorage.removeItem(StorageKeys.APP_DATA);
        }
    }, [setToLocal]);

    const getUser = React.useCallback(() => {
        if (!token) {
            return null;
        }

        try {
            const decoded = jwtDecode(token) as { [key: string]: string; };
            const { user } = decoded;
            const userInfo: CurrentUser = JSON.parse(user);
            return userInfo;
        } catch (error) {
            return null;
        }
    }, [token]);

    const isAuthenticated = React.useCallback(() => {
        if (!expiration) {
            return false;
        }

        const isValid = Date.parse(expiration) > Date.now();
        return isValid;
    }, [expiration]);

    const isInRole = (key?: string) => {
        if (!key) {
            return false;
        }
        const { rCodes } = getUser() ?? {};

        if (!rCodes) {
            return false
        }

        return rCodes[key] ?? false;
    }


    const getToken = React.useCallback(() => {
        if (token) {
            return token;
        }
        return '';
    }, [token]);

    return (
        <AuthContext.Provider value={{ getToken, isInRole, setUpAuth, getUser, isAuthenticated, ...codes }}>
            {children}
        </AuthContext.Provider>
    );
}
