import React, { useCallback, useEffect, useState } from "react";
import { Transaction } from "appDtos";
import { TransactionPaths } from "../api/constants";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";

interface ApproverType {
    transactions: Transaction[];
    count: number;
    isLoading: boolean;
    refresh: () => void;
}

export const ApproverContext = React.createContext<ApproverType>({
    transactions: [],
    count: 0,
    isLoading: true,
    refresh: () => { },
});

export const ApproverProvider = ({ children }: any) => {
    const API = useApi();
    const [data, setData] = useState({ transactions: [], count: 0, isLoading: true });
    const { isAuthenticated, getUser } = useAuth();
    const [lastUpdate, setLastUpdate] = useState<any>(null);
    const isAuth = isAuthenticated();
    const isR30 = Boolean(getUser()?.rCodes['30']);
    const refresh = useCallback(() => setLastUpdate(Date.now), []);

    useEffect(() => {
        if (isAuth && (isR30 || lastUpdate)) {
            (async () => {
                try {
                    const res = await API.get(TransactionPaths.GetTransactionsPendingApproval());
                    setData({
                        ...data,
                        transactions: res.data,
                        count: res.data.length,
                        isLoading: false,
                    });
                    lastUpdate && setLastUpdate(null);
                } catch (error) {
                    console.log(error);
                    setData({ ...data, isLoading: false });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, lastUpdate]);

    return (
        <ApproverContext.Provider value={{ ...data, refresh }}>
            {children}
        </ApproverContext.Provider>
    );
}
