import { useEffect, useMemo, useState } from 'react'
import { Icon, PageHeader } from '../../shared/components'
import { Button, Card, Col, Container, InputGroup, Row, Form } from 'react-bootstrap';
import { RejectedAssetDto } from 'appDtos';
import { AssetPaths } from '../../shared/api/constants';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AlertComponent } from '../../shared/components';
import { Loading } from '../../shared/components';
import { AppPaths } from '../../shared/appConstants';
import { formatDate } from '../../shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import { faClose, faList, faTable } from '@fortawesome/free-solid-svg-icons';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { RejectedAssetResultTable } from './AssetResultCard';

export function RecentRejections() {
  const goTo = useNavigate();
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState<RejectedAssetDto[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTableView, setIsTableView] = useState(() => {
    const view = localStorage.getItem(StorageKeys.REJECTIONS_TABLE_VIEW);
    return view ? JSON.parse(view) : false;
  });

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const res = await API.get(AssetPaths.GetRecentRejections());
        setAssets(res.data)
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const assetsToDisplay = useMemo(() =>{
    if (!assets.length || !searchTerm) return assets;
    return assets.filter(t => t.name.toLowerCase().includes(searchTerm.toLowerCase()));
  },[searchTerm, assets])

  const handleViewChange = () => {
    const view = !isTableView;
    localStorage.setItem(StorageKeys.REJECTIONS_TABLE_VIEW, `${view}`);
    setIsTableView(view);
  };

  return (
    <Container className="mb-3">
      <PageHeader pageTitle="Assets With Recent Rejection" />
      <AlertComponent message={errorMessage} />
      <InputGroup className="mb-3">
        <Form.Control
          className="border border-1 border-success"
          size="lg"
          placeholder="Enter asset name"
          aria-label="Search term"
          name="searchTerm"
          aria-describedby="search"
          value={searchTerm ?? ''}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
        {Boolean(searchTerm) && <Button onClick={() => setSearchTerm('')} className="fw-bolder" variant="outline-success" id="button-clear-search">
          <Icon icon={faClose} />
        </Button>}
        <Button onClick={handleViewChange} className="mx-1 fw-bolder col-1" variant="success" id="button-view">
          <Icon icon={isTableView ? faList : faTable} /> {isTableView ? 'List' : 'Table'}
        </Button>
      </InputGroup>
      {
        loading ?
          <Loading /> : (
            assetsToDisplay.length ?
              (isTableView ? <RejectedAssetResultTable assets={assetsToDisplay} /> :
                assetsToDisplay.map(asset => (
                  <Card
                    onClick={() => goTo(AppPaths.getAssetDetail(asset.assetId))}
                    className="mb-1 asset-card"
                    key={asset.assetId}
                  >
                    <Card.Header>
                      <Card.Title className="fw-bold mb-0">{asset.name}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="3">
                          <div className="mt-1">Created Date</div>
                          <Card.Subtitle className="fw-bold">{formatDate(asset.createdDate)}</Card.Subtitle>
                        </Col>
                        <Col md="3">
                          <div className="mt-1">Rejected Date</div>
                          <Card.Subtitle className="fw-bold">{formatDate(asset.rejectedDate)}</Card.Subtitle>
                        </Col>
                        <Col md="3">
                          <div className="mt-1">Rejected By</div>
                          <Card.Subtitle className="fw-bold">{asset.rejectedBy}</Card.Subtitle>
                        </Col>
                        <Col md="3">
                          <div className="mt-1">Rejection Reason</div>
                          <div className="text-with-ellipsis" title={asset.rejectionReason}>{asset.rejectionReason}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))) :
              <div>No result to display.</div>)
      }
    </Container>
  )
}
