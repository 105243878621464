import { useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { SpinnerComponent } from '../../../shared/components';
import { Required } from '../../../shared/components';

export function CreateCostCenter() {
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const goTo = useNavigate();
  const API = useApi();
  const [costCenter, setCostCenter] = useState('');

  const handleInputChange = (event: any) => {
    if (formError) setFormError('');
    if (errorMessage) setError('');

    setCostCenter(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!costCenter) return setFormError('Cost center name is required.');

    setIsLoading(true);
    const post = async () => {
      try {
        const response = await API.post(AdministrationPaths.CreateCostCenter(), { name: costCenter });
        if (response?.data) {
          goTo(AppPaths.getCostCenters())
        }
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }
    post();
  };

  return (
    <Container>
      <PageHeader pageTitle='Create Cost Center' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Cost Center Name<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="costCenter"
            autoComplete="off"
            value={costCenter}
            onChange={handleInputChange}
            autoFocus
            isInvalid={Boolean(formError)}
          />
          <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
        </Form.Group>

        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>
    </Container>
  );
}
