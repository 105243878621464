import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { BackButton } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { SpinnerComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { Required } from '../../../shared/components';

export function EditSupplier() {
  const { id } = useParams();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const goTo = useNavigate();
  const API = useApi();
  const [supplier, setSupplier] = useState('');

  useEffect(() => {
    const get = async () => {
      try {
        const response = await API.get(AdministrationPaths.GetSupplierDetail(Number(id)));
        if (response.data) {
          setSupplier(response.data.name);
        }
      } catch (error) {
        setError(error)
      }
      setIsLoading(false);
    }
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event: any) => {
    if (formError) setFormError('');
    if (errorMessage) setError('');

    setSupplier(event.target.value);
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (!supplier) return setFormError('Supplier is required.');

    setIsLoading(true);
    (async () => {
      try {
        const supplierId = Number(id);
        await API.put(AdministrationPaths.UpdateSupplier(), { supplierId,  name: supplier });
        goTo(AppPaths.getSupplierDetail(supplierId))
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='Edit Supplier' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Supplier Name<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="supplier"
            autoComplete="off"
            value={supplier}
            onChange={handleInputChange}
            isInvalid={Boolean(formError)}
          />
          <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
        </Form.Group>

        <BackButton />
        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>
    </Container>
  );
}
