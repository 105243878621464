const AdminBasePath = 'api/v1/Administration';
const TransactionBasePath = 'api/v1/Transaction';
const ReviewBasePath = 'api/v1/Review';
const AssetBasePath = 'api/v1/Asset';
const AccountBasePath = 'api/v1/Account';
const SettingBasePath = 'api/v1/Setting';
const DashboardBasePath = 'api/v1/Dashboard';

// AdministrationController paths
const AdministrationPaths = {
    // USERS REGION
    UpdateUserRole: () => `${AdminBasePath}/UpdateUserRole`,
    GetUsers: () => `${AdminBasePath}/Users`,
    GetUserDetail: (userId: string) => `${AdminBasePath}/Users/${userId}`,
    UpdateUserDetail: () => `${AdminBasePath}/Users`,
    DeleteUserDetail: (userId: string) => `${AdminBasePath}/Users/${userId}`,

    // ROLES
    GetRoles: () => `${AdminBasePath}/Roles`,

    // CATEGORIES REGION
    CreateCategory: () => `${AdminBasePath}/Categories`,
    UpdateCategory: () => `${AdminBasePath}/Categories`,
    GetCategories: () => `${AdminBasePath}/Categories`,
    GetCategoryDetail: (categoryId: number) => `${AdminBasePath}/Categories/${categoryId}`,
    DeleteCategories: (categoryId: number) => `${AdminBasePath}/Categories/${categoryId}`,

    // COST CENTERS REGION
    CreateCostCenter: () => `${AdminBasePath}/CostCenters`,
    UpdateCostCenter: () => `${AdminBasePath}/CostCenters`,
    GetCostCenters: () => `${AdminBasePath}/CostCenters`,
    GetCostCenterDetail: (costCenterId: number) => `${AdminBasePath}/CostCenters/${costCenterId}`,
    DeleteCostCenters: (costCenterId: number) => `${AdminBasePath}/CostCenters/${costCenterId}`,

    // SUPPLIER
    CreateSupplier: () => `${AdminBasePath}/Suppliers`,
    UpdateSupplier: () => `${AdminBasePath}/Suppliers`,
    GetSuppliers: () => `${AdminBasePath}/Suppliers`,
    GetSupplierDetail: (supplierId: number) => `${AdminBasePath}/Suppliers/${supplierId}`,
    DeleteSuppliers: (supplierId: number) => `${AdminBasePath}/Suppliers/${supplierId}`,

    // LOCATIONS REGION
    CreateLocation: () => `${AdminBasePath}/Locations`,
    UpdateLocation: () => `${AdminBasePath}/Locations`,
    GetLocations: () => `${AdminBasePath}/Locations`,
    GetLocationDetail: (locationId: number) => `${AdminBasePath}/Locations/${locationId}`,
    DeleteLocation: (locationId: number) => `${AdminBasePath}/Locations/${locationId}`,

    // CUSTODIAN REGION
    CreateCustodian: () => `${AdminBasePath}/Custodians`,
    UpdateCustodian: () => `${AdminBasePath}/Custodians`,
    GetCustodians: () => `${AdminBasePath}/Custodians`,
    GetCustodianDetail: (custodianId: number) => `${AdminBasePath}/Custodians/${custodianId}`,
    DeleteCustodian: (custodianId: number) => `${AdminBasePath}/Custodians/${custodianId}`,
};

// AccountController paths
const AccountPaths = {
    basePath: 'api/v1/Account',

    Register: () => `${AccountBasePath}/Register`,
    SendUserAccountActivationRequest: () => `${AccountBasePath}/Activation`,
    Login: () => `${AccountBasePath}/Login`,
    RegisterAdmin: () => `${AccountBasePath}/RegisterAdmin`,
    ConfirmAdminCode: (code: string) => `${AccountBasePath}/Confirm/${code}`,
    ConfirmEmail: () => `${AccountBasePath}/ConfirmEmail`,
    ResetPassword: () => `${AccountBasePath}/ResetPassword`,
    ForgotPassword: () => `${AccountBasePath}/ForgotPassword`,
    ChangePassword: () => `${AccountBasePath}/ChangePassword`,
};

// SettingController paths
const SettingPaths = {
    GetAppCodes: () => `${SettingBasePath}/AppCodes`,
};

const AssetPaths = {
    // HTTP POST
    Register: () => `${AssetBasePath}`,
    Search: () => `${AssetBasePath}/Search`,

    // HTTP GET
    GetFilters: () => `${AssetBasePath}/Filters`,
    GetAssets: () => `${AssetBasePath}`,
    GetRecentRejections: () => `${AssetBasePath}/Rejections`,
    GetAssetDetail: (assetId: number) => `${AssetBasePath}/${assetId}`,
    GetAssetDetailLite: (assetId: number) => `${AssetBasePath}/${assetId}/DetailLite`,
    GetAssetAuditTrials: (assetId: number) => `${AssetBasePath}/${assetId}/Audits`,
    GetTransactions: (assetId: number) => `${AssetBasePath}/${assetId}/Transactions`,

    // HTTP DELETE
    DeleteAsset: (assetId: number) => `${AssetBasePath}/${assetId}`,
    DeleteTransfer: (transferId: number) => `${AssetBasePath}/Transfers/${transferId}`,
};

const TransactionPaths = {
    // HTTP POST
    Update: () => `${TransactionBasePath}/Update`,
    Transfer: () => `${TransactionBasePath}/Transfer`,
    Dispose: () => `${TransactionBasePath}/Dispose`,

    // HTTP GET
    GetTransactionsPendingApproval: () => `${TransactionBasePath}/PendingApproval`,
};

const ReviewPaths = {
    // HTTP POST
    RejectTransaction: () => `${ReviewBasePath}/Reject`,
    ApproveTransaction: () => `${ReviewBasePath}/Approve`,
    RevertApproval: () => `${ReviewBasePath}/RevertApproval`,
};

const DashboardPaths = {
    // HTTP GET
    GetDashboard: () => `${DashboardBasePath}`,
    GetCachedDashboard: () => `${DashboardBasePath}/Cached`,
    // HTTP POST
    GenerateReport: () => `${DashboardBasePath}/Report`,
};

export {
    AssetPaths,
    AdministrationPaths,
    AccountPaths,
    SettingPaths,
    ReviewPaths,
    TransactionPaths,
    DashboardPaths
};
