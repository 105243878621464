import { Button, Modal, Form } from 'react-bootstrap';
import { AlertComponent } from '../../shared/components';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { Loading } from '../../shared/components';
import { faCheck, faClose, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ReviewPaths } from '../../shared/api/constants';
import { Icon } from '../../shared/components';
import { Required } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';

export enum ReviewType {
  NONE = 'None',
  APPROVE = 'Approve',
  REJECT = 'Reject',
};

export type ReviewAction = { reviewType: ReviewType, transactionId: number };

export type ReviewModalProps = {
  show: boolean;
  reviewAction: ReviewAction;
  okHandler: () => void;
  cancelHandler: () => void;
};

export function ReviewModal({ okHandler, cancelHandler, reviewAction, show }: ReviewModalProps) {
  const [rejectionReason, setRejectionReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const { reviewType, transactionId } = reviewAction;

  const renderBody = () => {
    if (successMessage) {
      return null;
    } else if (reviewType === ReviewType.APPROVE) {
      return (
        <div>
          <p>Are you sure you want to approve this asset?</p>
          <p className="fw-bold"> <Icon icon={faExclamationCircle} /> <strong>Important: </strong></p>
          <p>This action will move the asset status to "Active". If this asset transaction is removing any document, it will NOT be recover after approval.</p>
          <p>Please confirm your choice below:</p>
        </div>
      );
    } else if (reviewType === ReviewType.REJECT) {
      return (
        <div>
          {!errorMessage && <>
            <p>Are you sure you want to reject this?</p>
            <p>This action will change the status of this asset to "Reject".</p>
            <p>Please enter the required "Rejection Reason" below and click Reject or click cancel to return.</p>
          </>}
          <Form.Label className="my-3 fw-bold" htmlFor="rejectionReason">Rejection Reason<Required /></Form.Label>
          <Form.Control
            autoComplete='off'
            autoFocus
            as="textarea"
            placeholder='Enter rejection reason.'
            id="rejectionReason"
            aria-describedby="rejectionReason"
            onChange={(e: any) => e.target.value.length <= 255 && setRejectionReason(e.target.value)} value={rejectionReason} />
          <div className="text-muted mt-2">{`${rejectionReason.length} of 255`}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const path = reviewType === ReviewType.APPROVE ? ReviewPaths.ApproveTransaction() : ReviewPaths.RejectTransaction();
    const body = { transactionId, rejectionReason };

    (async () => {
      try {
        const res = await API.post(path, body);
        setSuccessMessage(res.data.message);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  };

  const closeReviewModal = () => {
    cancelHandler();
    if (successMessage) okHandler();
    if (rejectionReason) setRejectionReason('');
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setError('');
    if (rejectionReason) setRejectionReason('');
  };

  return (
    <Modal show={show} onHide={closeReviewModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="border-3 border-buttom border-dark bg-success text-light">
        <Modal.Title className="fw-bold">Please Confirm!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertComponent message={errorMessage || successMessage} variant={successMessage ? 'success' : 'danger'} />
        {loading ? <Loading /> : renderBody()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeReviewModal} className="fw-bold">
          {<Icon icon={faClose} />} Close
        </Button>
        <Button disabled={loading || (reviewType === ReviewType.REJECT && !rejectionReason.length) || !!successMessage} variant="success" onClick={handleSubmit} className="fw-bold">
          <Icon icon={faCheck} /> {reviewType}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
