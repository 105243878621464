import React from 'react';
import { Container } from 'react-bootstrap';
import { PageHeader } from '../../shared/components';

export const UserProfile = () => {
  return (
    <Container>
      <PageHeader pageTitle='User Profile' />
    </Container>
  );
}
