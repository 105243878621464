import { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CustodianDetailDto, UpdateCustodianRequest } from 'appDtos';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { counties, AppPaths, custodianTypes, custodianTypesMapper, CustodianTypes } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { Required } from '../../../shared/components';
import { SpinnerComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { isValidEmail } from '../../../shared/utils/validators';
import { BackButton } from '../../../shared/components';

export function EditCustodian() {
  const { id } = useParams();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [custodian, setCustodian] = useState<UpdateCustodianRequest>({
    custodianId: 0,
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phoneNumber: '',
    type: -1,
    address: '',
    cityOrTown: '',
    county: '',
  });
  const [formErrors, setFormError] = useState({
    custodianId: '',
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phoneNumber: '',
    type: '',
    address: '',
    cityOrTown: '',
    county: '',
  });
  const goTo = useNavigate();
  const API = useApi();

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get<CustodianDetailDto>(AdministrationPaths.GetCustodianDetail(Number(id)));
        if (response.data) {
          const { custodianId, type, firstName, lastName, name, email, phoneNumber, address, cityOrTown, county } = response.data;
          setCustodian({ custodianId, type: custodianTypesMapper[type as keyof CustodianTypes], firstName, lastName, name, email, phoneNumber, address, cityOrTown, county });
        }
      } catch (error) {
        setError(error)
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (formErrors[name as keyof UpdateCustodianRequest]) setFormError({ ...formErrors, [name]: '' });
    if (errorMessage) setError('');

    setCustodian({ ...custodian, [name]: value });
  };

  const type = Number(custodian.type);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const errors = { ...formErrors }
    if (type !== 0 && type !== 1) errors.type = 'Custodian type is required.';
    if (type === 0 && !custodian.firstName) errors.firstName = 'First name is required when custodian type is a person.';
    if (type === 0 && !custodian.lastName) errors.lastName = 'Last name is required when custodian type is a person.';
    if (type === 1 && !custodian.name) errors.name = 'Custodian name is required when custodian type is not a person.';
    if (custodian.phoneNumber && !Number(custodian.phoneNumber)) errors.phoneNumber = 'Phone Number is invalid.';
    if (custodian.email && !isValidEmail(custodian.email)) errors.email = 'Email is invalid.';
    if (!custodian.address) errors.address = 'Address is required.';
    if (!custodian.cityOrTown) errors.cityOrTown = 'City or town is required.';
    if (!counties.includes(custodian.county)) errors.county = 'County is required.';

    if (Object.values(errors).some(v => v)) return setFormError(errors);

    if (type === 0) custodian.name = null;
    if (type === 1) custodian.firstName = null;
    if (type === 1) custodian.lastName = null;
    if (!custodian.email) custodian.email = null;
    if (!custodian.phoneNumber) custodian.phoneNumber = null;
    custodian.type = type;

    setIsLoading(true);
    (async () => {
      try {
        await API.put(AdministrationPaths.UpdateCustodian(), custodian);
        goTo(AppPaths.getCustodianDetail(custodian.custodianId))
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='Edit Asset Custodian' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">

        <Form.Group controlId="CustodianType" className="mt-3">
          <Form.Label className="fw-bold">Custodian Type<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.type)} size="lg" value={custodian.type} name="type" onChange={handleInputChange}>
            <option>Select Custodian Type</option>
            {custodianTypes.map(t => <option value={t.value} key={t.value}>{t.displayValue}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.type}</Form.Control.Feedback>
        </Form.Group>

        {(type === 0) && <><Form.Group controlId="firstName" className="mt-3">
          <Form.Label className="fw-bold">First Name<Required /></Form.Label>
          <Form.Control
            autoFocus={type === 0}
            size="lg"
            type="text"
            name="firstName"
            className=""
            placeholder="Enter first name."
            value={custodian.firstName ?? ''}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.firstName)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.firstName}</Form.Control.Feedback>
        </Form.Group>

          <Form.Group controlId="lastName" className="mt-3">
            <Form.Label className="fw-bold">Last Name<Required /></Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="lastName"
              placeholder="Enter last name."
              value={custodian.lastName ?? ''}
              onChange={handleInputChange}
              isInvalid={Boolean(formErrors.lastName)}
            />
            <Form.Control.Feedback type="invalid">{formErrors.lastName}</Form.Control.Feedback>
          </Form.Group></>}

        {(type === 1) && <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Custodian Name<Required /></Form.Label>
          <Form.Control
            autoFocus={type === 1}
            size="lg"
            type="text"
            name="name"
            placeholder="Enter custodian name."
            value={custodian.name ?? ''}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.name)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
        </Form.Group>}

        <Form.Group controlId="phoneNumber" className="mt-3">
          <Form.Label className="fw-bold">Phone Number</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="phoneNumber"
            placeholder="Enter custodian phone number."
            value={custodian.phoneNumber ?? ''}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.phoneNumber)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.phoneNumber}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email" className="mt-3">
          <Form.Label className="fw-bold">Email</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="email"
            placeholder="Enter email."
            value={custodian.email ?? ''}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.email)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="address" className="mt-3">
          <Form.Label className="fw-bold">Physical Address<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="address"
            placeholder="Enter custodian address."
            value={custodian.address}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.address)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.address}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="cityOrTown" className="mt-3">
          <Form.Label className="fw-bold">City or Town<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="cityOrTown"
            placeholder="Enter custodian city or town."
            value={custodian.cityOrTown}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.cityOrTown)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.cityOrTown}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="county" className="mt-3">
          <Form.Label className="fw-bold">County<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.county)} size="lg" value={custodian.county} name="county" onChange={handleInputChange}>
            <option>Select county</option>
            {counties.map(c => <option key={c}>{c}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.county}</Form.Control.Feedback>
        </Form.Group>
        
        <BackButton />
        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>
    </Container>
  );
}
