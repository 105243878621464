import { faArrowCircleLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from './Icon'
import { useNavigate } from 'react-router-dom';

export function BackButton() {
  const goTo = useNavigate();
  return (
    <Button onClick={() => goTo(-1)} variant="secondary" size='lg' className="mt-5 fw-bold">
      {<Icon icon={faArrowCircleLeft} />} Back
    </Button>
  )
}

export const NavButton = ({ path }: { path: string }) => {
  const goTo = useNavigate();

  return path ? <Button onClick={() => goTo(path)} variant="success" size='lg' className="fw-bold">
    {<Icon icon={faPlus} />} Add New
  </Button> : null;
}
