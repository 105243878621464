import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { CategoryDto } from 'appDtos';
import { Container, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { Loading } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';

export function Categories() {
  const [categories, setCategories] = useState<CategoryDto[]>([]);
  const [loading, setLoading] = useState(true);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetCategories());
        if (response.data) {
          setCategories(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <PageHeader pageTitle='Categories' navPath={AppPaths.getCreateCategory()}/>
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        categories.length > 0 ?
          <ListGroup numbered as="ol">
            {
              categories.map((category, i) => (
                <ListGroup.Item key={category.categoryId} action as="li" className="d-flex justify-content-between align-items-start clicable" onClick={() => goTo(AppPaths.getCategoryDetail(category.categoryId))}>
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{category.name}</div>
                  </div>
                </ListGroup.Item>
              ))
            }
          </ListGroup>
          : (
            <AlertComponent message="No category has been created." variant="info" />
          )
      )}
    </Container>)
}
