import React, { useState } from 'react'
import { PageHeader } from '../../../shared/components';
import { Button, Container, Form } from 'react-bootstrap';
import { RegisterUserRequest, RegisterUserResponse } from 'appDtos';
import { isValidEmail } from '../../../shared/utils/validators';
import { useApi } from '../../../shared/hooks/useApi';
import { AccountPaths } from '../../../shared/api/constants';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from '../../../shared/appConstants';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { AlertComponent } from '../../../shared/components';
import {  faCheck } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../shared/components';
import { formatPhoneNumber } from '../../../shared/utils/utils';
import { Required } from '../../../shared/components';

export function CreateUser() {
  const { setError, errorMessage } = useApiHandleError();
  const [formErrors, setFormError] = useState<any>({});
  const goTo = useNavigate();
  const API = useApi();

  const [user, setUser] = useState<RegisterUserRequest>({
    firstName: '',
    lastName: '',
    email: '',
    confirmedEmail: '',
    phone: null,
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (formErrors[name]) setFormError({ ...formErrors, [name]: '', });
    if (errorMessage) setError('');

    setUser({...user,[name]: value});
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formErrors = { firstName: '', lastName: '', email: '', confirmedEmail: '', phone: '' };

    if (!user.firstName) formErrors.firstName = 'First name is required.';
    if (!user.lastName) formErrors.lastName = 'Last name is required.';
    if (!isValidEmail(user.email)) formErrors.email = 'Email is not valid.';
    if (!user.confirmedEmail || user.confirmedEmail !== user.email) formErrors.confirmedEmail = 'Confirmed email does not match.';
    if (user.phone && !Number(user.phone)) formErrors.phone = 'Phone number is not valid';

    if (Object.values(formErrors).some(v => v)) return setFormError(formErrors);

    if (user.phone) user.phone = formatPhoneNumber(user.phone);

    try {
      const response = await API.post<RegisterUserResponse>(AccountPaths.Register(), user);
      if (response?.data) {
        goTo(AppPaths.getUserDetail(response.data))
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Container>
      <PageHeader pageTitle='Create User' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit}>
        {/* First Name */}
        <Form.Group controlId="firstName" className="mt-3">
          <Form.Label className="fw-bold">First Name<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="firstName"
            autoComplete="off"
            value={user.firstName}
            onChange={handleInputChange}
            autoFocus
            isInvalid={Boolean(formErrors.firstName)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.firstName}</Form.Control.Feedback>
        </Form.Group>

        {/* Last Name */}
        <Form.Group controlId="lastName" className="mt-3">
          <Form.Label className="fw-bold">Last Name<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="lastName"
            autoComplete="off"
            value={user.lastName}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.lastName)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.lastName}</Form.Control.Feedback>
        </Form.Group>

        {/* Email */}
        <Form.Group controlId="email" className="mt-3">
          <Form.Label className="fw-bold">Email<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="email"
            name="email"
            autoComplete="off"
            value={user.email}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.email)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>

        {/* Confirm Email */}
        <Form.Group controlId="confirmedEmail" className="mt-3">
          <Form.Label className="fw-bold">Confirm Email<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="email"
            name="confirmedEmail"
            autoComplete="off"
            value={user.confirmedEmail}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.confirmedEmail)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.confirmedEmail}</Form.Control.Feedback>
        </Form.Group>

        {/* Phone */}
        <Form.Group controlId="phone" className="mt-3">
          <Form.Label className="fw-bold">Phone (optional)</Form.Label>
          <Form.Control
            size="lg"
            type="tel"
            name="phone"
            autoComplete="off"
            value={formatPhoneNumber(user.phone || '') }
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.phone)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.phone}</Form.Control.Feedback>
        </Form.Group>

        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          <Icon icon={faCheck} /> Submit
        </Button>
      </Form>
    </Container>
  );
}
