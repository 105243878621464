import { LoginForm } from '../auth/Login'
import { useAuth } from '../../shared/hooks/useAuth';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Loading } from '../../shared/components';

export const Home = () => {
    const { setUpAuth, isAuthenticated } = useAuth();
    const goto = useNavigate();
    const [render, setRender] = useState(false);

    useEffect(() => {
        if (window.localStorage.getItem(StorageKeys.APP_TOKEN) && isAuthenticated()) {
            goto('/dashboard');
        }else{
            setRender(true);
        }
    }, [isAuthenticated, goto])

    if (!render) {
        return <Loading/>
    }

    return <LoginForm setUpAuth={setUpAuth} />;
}
