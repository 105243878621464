import { Icon, PageHeader } from '../../shared/components'
import { Button, Card, Col, Container, InputGroup, Row, Form } from 'react-bootstrap'
import { AlertComponent } from '../../shared/components';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AppPaths } from '../../shared/appConstants';
import { formatDate } from '../../shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useApprovers } from '../../shared/hooks/useApprovers';
import { Loading } from '../../shared/components';
import { useEffect, useMemo, useState } from 'react';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { faClose, faList, faTable } from '@fortawesome/free-solid-svg-icons';
import { PendingReviewResultTable } from './AssetResultCard';

export function PendingApprovals() {
  const goTo = useNavigate();
  const { errorMessage } = useApiHandleError();
  const { transactions, isLoading, refresh } = useApprovers();
  const [searchTerm, setSearchTerm] = useState('');
  const [isTableView, setIsTableView] = useState(() => {
    const view = localStorage.getItem(StorageKeys.PENDING_TABLE_VIEW);
    return view ? JSON.parse(view) : false;
  });

  const transactionsToDisplay = useMemo(() =>{
    if (!transactions.length || !searchTerm) return transactions;
    return transactions.filter(t => t.assetName.toLowerCase().includes(searchTerm.toLowerCase()));
  },[searchTerm, transactions])

  useEffect(() => {
    refresh();
  }, [refresh])

  const handleViewChange = () => {
    const view = !isTableView;
    localStorage.setItem(StorageKeys.REJECTIONS_TABLE_VIEW, `${view}`);
    setIsTableView(view);
  };

  return (
    <Container className="mb-3">
      <PageHeader pageTitle="Pending Review" />
      <AlertComponent message={errorMessage} />
      <InputGroup className="mb-3">
        <Form.Control
          className="border border-1 border-success"
          size="lg"
          placeholder="Enter asset name"
          aria-label="Search term"
          name="searchTerm"
          aria-describedby="search"
          value={searchTerm ?? ''}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
        {Boolean(searchTerm) && <Button onClick={() => setSearchTerm('')} className="fw-bolder" variant="outline-success" id="button-clear-search">
          <Icon icon={faClose} />
        </Button>}
        <Button onClick={handleViewChange} className="mx-1 fw-bolder col-1" variant="success" id="button-view">
          <Icon icon={isTableView ? faList : faTable} /> {isTableView ? 'List' : 'Table'}
        </Button>
      </InputGroup>
      {
        isLoading ? <Loading /> : transactionsToDisplay.length ? (isTableView ? <PendingReviewResultTable transactions={transactionsToDisplay} /> : transactionsToDisplay.map(transaction => (
          <Card key={transaction.assetTransactionId} className="mb-1 asset-card" onClick={() => goTo(AppPaths.getAssetDetail(transaction.assetId))}>
            <Card.Header className="d-flex justify-content-between align-items-start">
              <Card.Title className="fw-bold mb-0">{transaction.assetName}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="3">
                  <div>Created Date</div>
                  <Card.Subtitle className="fw-bold">{formatDate(transaction.date)}</Card.Subtitle>
                </Col>
                <Col md="3">
                  <div>Created By</div>
                  <Card.Subtitle className="fw-bold">{transaction.createdBy}</Card.Subtitle>
                </Col>
                <Col md="3">
                  <div>Transaction Type</div>
                  <Card.Subtitle className="fw-bold">{transaction.type}</Card.Subtitle>
                </Col>
                <Col md="3">
                  <div>Status</div>
                  <Card.Subtitle className="fw-bold">{transaction.status}</Card.Subtitle>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))) : <div>No result to display.</div>
      }
    </Container>
  )
}
