import { Button } from 'react-bootstrap';
import { Icon } from '../../shared/components';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';

export type SelectedValues ={
  searchTerm: string;
  category: string;
  custodian: string;
  location: string;
  supplier: string;
  costCenter: string;
  createdFrom: string;
  createdTo: string;
  status: string;
  condition: string;
}

export function SelectedFilter({ values, unselectValue, clearAll }: { values: SelectedValues; unselectValue: (key: keyof SelectedValues) => void; clearAll: () => void; }) {
  const selected = Object.entries(values).filter(([k, v]) => v);
  return (
    <div className="mb-2">
      {selected.map(([k, v]) => (
        <Button variant="outline-success" title="Click to remove." className="btn-sm rounded m-1" key={k} onClick={() => unselectValue(k as any)}>
          {k.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase())}: <strong>{`${v}`}</strong> <Icon icon={faXmarkCircle} />
        </Button>))}
      {selected.length > 1 && <Badge pill title="Clear all." className="p-2 clicable" bg="success" key="clear" onClick={clearAll}>
        Clear all <Icon icon={faXmarkCircle} />
      </Badge>}
    </div>
  );
}
