import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { Icon } from './Icon';
import { faArrowCircleDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { Filter, Filters, SearchValues } from 'appDtos';
import { filterSorter } from '../utils/utils';
import { DatePickerComponent, DateRange } from './DateRangePicker';
import { useEffect, useState } from 'react';

type Props = {
  show: boolean;
  searchCriteria: SearchValues;
  handleSubmit: () => void;
  handleClear: () => void;
  handleClose: () => void;
  handleOnChange: (e: any) => void;
  filters?: Filters;
  setDate: (range: DateRange) => void;
};

export function FiltersModal({ setDate, handleSubmit, handleClear, handleClose, handleOnChange, searchCriteria, filters, show }: Props) {
  const [cleared, setCleared] = useState(false);

  const close = () => handleClose();

  const apply = () => {
    handleClose();
    cleared ? handleClear() : handleSubmit();
  };

  const values = cleared ? {
    createdFrom: null,
    createdTo: null,
    condition: '',
    category: '',
    costCenter: '',
    custodian: '',
    location: '',
    status: '',
    supplier: '',
  } : searchCriteria;

  useEffect(() => {
    if (show) {
      setCleared(false);
    }
  }, [show])

  const sortedConditions = filters?.conditions.filter((c: Filter) => ['Good', 'Damaged','Fair'].includes(c.value)).sort(filterSorter) ?? [];
  const sortedLocations = filters?.locations.sort(filterSorter) ?? [];
  const sortedCustodians = filters?.custodians.sort(filterSorter) ?? [];
  const sortedCostCenters = filters?.costCenters.sort(filterSorter) ?? [];
  const sortedCategories = filters?.categories.sort(filterSorter) ?? [];
  const sortedSuppliers = filters?.suppliers.sort(filterSorter) ?? [];
  const sortedStatuses = filters?.statuses.filter(v => v.value !== 'None').sort(filterSorter) ?? [];

  return (
    <Modal show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Advanced Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="dateRange">
          <Form.Label className="fw-bold">Created Date Range</Form.Label>
          <DatePickerComponent setDate={setDate} dateRange={{ from: values.createdFrom, to: values.createdTo }} />
        </Form.Group>

        <Form.Group controlId="status" className="mt-3">
          <Form.Label className="fw-bold">Status</Form.Label>
          <Form.Select value={values.status} name="status" onChange={handleOnChange}>
            <option value="">Select Status</option>
            {sortedStatuses.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="category" className="mt-3">
          <Form.Label className="fw-bold">Category</Form.Label>
          <Form.Select value={values.category} name="category" onChange={handleOnChange}>
            <option value="">Select Category</option>
            {sortedCategories.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="condition" className="mt-3">
          <Form.Label className="fw-bold">Condition</Form.Label>
          <Form.Select value={values.condition} name="condition" onChange={handleOnChange}>
            <option value="">Select Condition</option>
            {sortedConditions.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="location" className="mt-3">
          <Form.Label className="fw-bold">Location</Form.Label>
          <Form.Select value={values.location} name="location" onChange={handleOnChange}>
            <option value="">Select Location</option>
            {sortedLocations.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="custodian" className="mt-3">
          <Form.Label className="fw-bold">Custodian</Form.Label>
          <Form.Select value={values.custodian} name="custodian" onChange={handleOnChange}>
            <option value="">Select custodian</option>
            {sortedCustodians.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="costCenter" className="mt-3">
          <Form.Label className="fw-bold">Cost Center</Form.Label>
          <Form.Select value={values.costCenter} name="costCenter" onChange={handleOnChange}>
            <option value="">Select Cost Center</option>
            {sortedCostCenters.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="supplier" className="mt-3">
          <Form.Label className="fw-bold">Supplier</Form.Label>
          <Form.Select value={values.supplier} name="supplier" onChange={handleOnChange}>
            <option value="">Select supplier</option>
            {sortedSuppliers.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
        </Form.Group>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" disabled={cleared} onClick={() => setCleared(true)}>
          {<Icon icon={faClose} />} Clear All
        </Button>
        <Button variant="success" onClick={apply}>
          <Icon icon={faArrowCircleDown} /> Apply Filter
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
