
export const AppPaths: { [k: string]: (id?: any) => string } = {
    getHome: () => '/',
    getSearchAndFilter: () => '/search-and-filter',
    getRecentRejections: () => '/rejections',
    getCreateUser: () => '/create-user',
    getUsers: () => '/users',
    getUserDetail: (id?: string) => `/users/${id ?? ':id'}`,
    getEditUser: (id?: string) => `/users/edit/${id ?? ':id'}`,

    getCreateCategory: () => '/create-category',
    getCategories: () => '/categories',
    getCategoryDetail: (id?: number) => `/categories/${id || ':id'}`,
    getEditCategory: (id?: number) => `/categories/edit/${id || ':id'}`,

    getCreateCostCenter: () => '/create-costCenter',
    getCostCenters: () => '/costCenters',
    getCostCenterDetail: (id?: number) => `/costCenters/${id || ':id'}`,
    getEditCostCenter: (id?: number) => `/costCenters/edit/${id || ':id'}`,

    getCreateLocation: () => '/create-location',
    getLocations: () => '/locations',
    getLocationDetail: (id?: number) => `/locations/${id ?? ':id'}`,
    getEditLocation: (id?: number) => `/locations/edit/${id ?? ':id'}`,

    getCreateCustodian: () => '/create-custodian',
    getCustodians: () => '/custodians',
    getCustodianDetail: (id?: number) => `/custodians/${id ?? ':id'}`,
    getEditCustodian: (id?: number) => `/custodians/edit/${id ?? ':id'}`,

    getCreateSupplier: () => '/create-supplier',
    getSuppliers: () => '/suppliers',
    getSupplierDetail: (id?: number) => `/suppliers/${id ?? ':id'}`,
    getEditSupplier: (id?: number) => `/suppliers/edit/${id ?? ':id'}`,

    getRegisterAsset: () => '/register-asset',
    getRegistrations: () => '/pending-review',
    getAssetDetail: (id?: number) => `/assets/${id ?? ':id'}`,
    getEditAsset: (id?: number) => `/assets/edit/${id ?? ':id'}`,

    getTransferAsset: (id?: number) => `/transfer-asset/${id ?? ':id'}`,
    getTransfers: () => '/transfers',
    getTransferDetail: (id?: number) => `/transfers/${id ?? ':id'}`,
    getEditTransfer: (id?: number) => `/transfers/edit/${id ?? ':id'}`,

    getDashboard: () => '/dashboard',
    getReporting: () => '/reporting',
    getProfile: (id?: string) => `/profile/${id ?? ':id'}`,
};


export const counties = [
    "Bomi County",
    "Bong County",
    "Gbarpolu County",
    "Grand Bassa County",
    "Grand Cape Mount County",
    "Grand Gedeh County",
    "Grand Kru County",
    "Lofa County",
    "Margibi County",
    "Maryland County",
    "Montserrado County",
    "Nimba County",
    "River Cess County",
    "River Gee County",
    "Sinoe County",
];

export const custodianTypes = [
    { value: 0, displayValue: 'Person (a personnel or individual)' },
    { value: 1, displayValue: 'Non Person (department, section, or entity)' }
];

export enum custodianTypesEnum {
    PERSON = 'Person (a personnel or indiviual)',
    NONPERSON = 'Non Person (department, section, or entity)'
}

export const custodianTypesMapper: CustodianTypes = {
    'Person': 0,
    'NonPerson': 1,
}
export type CustodianTypes = {
    'Person': number,
    'NonPerson': number,
}