import React from 'react'
import { Spinner } from 'react-bootstrap'

export function SpinnerComponent() {
    return (
        <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    )
}
