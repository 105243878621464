import { useEffect, useState } from 'react'
import { Button, Card, Container, ListGroup } from 'react-bootstrap';
import { Icon } from '../../shared/components';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { DashboardPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { Dashboard } from 'appDtos';
import { AppPaths } from '../../shared/appConstants';
import { Loading } from '../../shared/components';
import { Bar, Pie } from 'react-chartjs-2';
import "chart.js/auto";

export function ChartsComponent() {
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (errorMessage) setError('');
    (async () => {
      try {
        const response = await API.get(DashboardPaths.GetDashboard());
        setDashboard(response.data);
      } catch (error) {
        setError(error)
      }
      setLoading(false);
      setRefresh(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const statuses = {
    labels: dashboard?.cards.filter(c => c.entityType === 'status').map(c => c.title) ?? [],
    datasets: [{
      label: 'Statuses',
      data: dashboard?.cards.filter(c => c.entityType === 'status').map(c => c.count) ?? [],
      backgroundColor: [
        'rgb(161 221 144)', '#34495e', '#3498db', '#f1c40f', '#9b59b6', 'red', '#79b9c1', '#05c3ee',
      ]
    }]
  }

  const conditions = {
    labels: dashboard?.cards.filter(c => c.entityType === 'condition').map(c => c.title) ?? [],
    datasets: [{
      label: 'Asset Conditions',
      data: dashboard?.cards.filter(c => c.entityType === 'condition').map(c => c.count) ?? [],
      backgroundColor: [
        'rgb(161 221 144)', 'yellow', 'red',
      ]
    }]
  }

  const handleChartClick = (entityType: string) => (e: any) => {
    const data = dashboard?.cards.find(c => c.entityType === entityType && c.title === e.chart.tooltip.title[0]);
    return data ? goTo(`${AppPaths.getSearchAndFilter()}/?${data?.entityType}=${data?.title}`) : false;
  }

  const statusesOpts = {
    onClick: handleChartClick('status'),
    plugins: {
      legend: {
        display: false
      }
    },
  }

  const conditionsOpts = {
    onClick: handleChartClick('condition'),
  }

  const totalAssets = dashboard?.cards.find(c => c.entityType === 'all');

  return (
    <Container fluid>
      <div className="page-header my-3 p-3 fw-bold rounded border border-1 border-success text-success d-flex justify-content-between align-items-center">
        <h1 className="h3 mb-0">Dashboard</h1>
        <Button className="fw-bold" title="Refresh dashboard" disabled={loading} onClick={() => setRefresh(true)} variant="outline-success"><Icon className={loading ? 'refreshing' : ''} icon={faRefresh} /> {loading ? 'Loading...' : 'Refresh'}</Button>
      </div>
      <AlertComponent message={errorMessage} />
      <div className="dashboard-chart-container">
        <Card className="col-3 border shadow-sm border-success">
          <Card.Body className=''>
            <Card.Title className="mb-1 fw-bold">{totalAssets?.title}</Card.Title>
            {loading ? <Loading /> : <div className="total-asset" title="Click to see all assets." onClick={() => goTo(`${AppPaths.getSearchAndFilter()}/?${totalAssets?.entityType}=${totalAssets?.title}`)}>{totalAssets?.count}</div>}
            {Boolean(dashboard?.recentActivities?.length) && <>
              <Card.Title className='fw-bold'>Recent Asset Activities</Card.Title>
              {loading ? <Loading /> : <ListGroup>
                {dashboard?.recentActivities.map((r, i) => <ListGroup.Item action key={i} onClick={() => goTo(AppPaths.getAssetDetail(r.assetId))}><span className='fw-bold'>{r.date}</span> <span>{r.value}</span></ListGroup.Item>)}
              </ListGroup>}
            </>}
          </Card.Body>
        </Card>
        <Card className="col-3 border shadow-sm border-success">
          <Card.Body className=''>
            <Card.Title className="mb-1 fw-bold">Conditions</Card.Title>
            {loading ? <Loading /> : <Pie data={conditions} options={conditionsOpts} />}
          </Card.Body>
        </Card>
        <Card className="col-6 border shadow-sm border-success">
          <Card.Body className=''>
            <Card.Title className="mb-1 fw-bold">Statuses</Card.Title>
            {loading ? <Loading /> : <Bar data={statuses} options={statusesOpts} />}
          </Card.Body>
        </Card>
      </div>
      <div className="dashboard-list-container">
        {dashboard?.lists.map((l, i) => (
          <Card key={i} className="col list-card border shadow-sm border-success">
            <Card.Subtitle className="list-card-title">{l.title}</Card.Subtitle>
            <Card.Body>
              {loading ? <Loading /> : <ListGroup key={i} className="list-group">
                {l.data.map(d => (
                  <ListGroup.Item
                    action
                    onClick={() => goTo(`${AppPaths.getSearchAndFilter()}/?${d.entityType}=${d.title}`)}
                    key={d.title}
                    className="d-flex justify-content-between">
                    <span>{d.title}</span><span>{d.count}</span>
                  </ListGroup.Item>))}
              </ListGroup>}
            </Card.Body>
          </Card>
        ))}
      </div>
    </Container>
  )
}
