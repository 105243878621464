import { faArrowCircleDown, faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AccountPaths } from '../../shared/api/constants';
import { Icon } from '../../shared/components';
import { PageHeader } from '../../shared/components';
import { SpinnerComponent } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { isValidEmail } from '../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';

export const PasswordForgetEmailSubmission = () => {
  const [successMessage, setResetSuccessMessage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<any>('');
  const API = useApi();
  const [email, setEmail] = useState('');
  const goTo = useNavigate();

  const handleInputChange = (event: any) => {
    if (formError) setFormError('');
    if (errorMessage) setError('');
    setEmail(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    const post = async () => {
      let message = '';

      if (!email) message = 'Email address is required.';
      else if (!isValidEmail(email)) message = 'Email address is not valid. Please provide a valid email.';

      if (message) {
        setLoading(false);
        return setFormError(message);
      }

      try {
        const res = await API.post(AccountPaths.ForgotPassword(), { email });
        setResetSuccessMessage(res.data.message);
        window.localStorage.removeItem(StorageKeys.APP_TOKEN);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    }

    post();
  };

  const renderGoToLoginButton = (variant: string) => {
    return (
      <Button className="btn btn-md fw-bold" onClick={() => goTo('/')} variant={`outline-${variant}`}>
        Go to Login
      </Button>);
  }

  const renderSuccessAlert = () => {
    return (
      <Alert show={true} variant="success">
        <Alert.Heading><Icon icon={faCheckCircle} /> Email was successfully received!</Alert.Heading>
        <p>{successMessage}</p>
        <hr />
        {renderGoToLoginButton('success')}
      </Alert>
    );
  }
  const renderErrorAlert = () => {
    return (
      <Alert show={true} variant="danger">
        <Alert.Heading><Icon icon={faExclamationCircle} /> An error occurred!</Alert.Heading>
        <p>{errorMessage}</p>
        <hr />
        {renderGoToLoginButton('danger')}
      </Alert>
    );
  }

  return (
    <Container>
      <PageHeader pageTitle='Forget Password' />
      {!Boolean(successMessage) && <Alert variant="info" className="border-3">
        <Alert.Heading><Icon icon={faInfoCircle} /> Email Submission</Alert.Heading>
        <p>
          No worries, we've got you covered!
          Please enter your registered email address below,
          and we'll send you a confirmation link to reset your password.
        </p>
        <p className="mb-0">
          Once you've received the email, click on the link provided,
          and you'll be guided through the password reset process.
          If you don't receive an email within a few minutes,
          please check your spam folder.
          If you're still having trouble,
          please contact the system administrator for assistance.
        </p>
        <hr />
        {renderGoToLoginButton('info')}

      </Alert>}
      {Boolean(successMessage) && renderSuccessAlert()}
      {Boolean(errorMessage) && renderErrorAlert()}

      {!Boolean(successMessage) && <Form noValidate onSubmit={handleSubmit}>

        <Form.Group controlId="email" className="mt-3">
          <Form.Label className="fw-bold">Email Address</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="email"
            value={email}
            onChange={handleInputChange}
            placeholder="john.doe@example.com"
            autoFocus
            isInvalid={Boolean(formError)}
          />
          <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
        </Form.Group>

        <Button variant="success" type="submit" size='lg' className="fw-bold mt-4">
          {loading ? <SpinnerComponent /> : <Icon icon={faArrowCircleDown} />} Submit
        </Button>
      </Form>}
    </Container>
  );
}
