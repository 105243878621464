import { AssetTransaction } from 'appDtos';
import { useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { AlertComponent } from '../../shared/components';
import { formatDate, mapColor } from '../../shared/utils/utils';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../shared/components';
import { ReviewAction, ReviewModal, ReviewType } from './ReviewModal';

type Props = {
    triggerRevert: (id: number) => void;
    refreshAssetDetail: () => void;
    printing?:boolean;
    assetTransactions: AssetTransaction[];
};

export function Transactions({printing, refreshAssetDetail, triggerRevert, assetTransactions }: Props) {
    const [reviewAction, setReviewAction] = useState<ReviewAction>({ reviewType: ReviewType.NONE, transactionId: 0 });
    const setReviewType = (transactionId: number, reviewType: ReviewType) => setReviewAction({ ...reviewAction, transactionId, reviewType });

    const handleUpdate = () => {
        refreshAssetDetail();
    }

    return (
        <>
            {Boolean(assetTransactions?.length) &&
                <ListGroup>
                    <ListGroup.Item key="header" as="li" className="d-flex justify-content-left align-items-baseline transaction-card">
                        <div className="col-1 fw-bold">#</div>
                        <div className="col fw-bold">Created By</div>
                        <div className="col fw-bold">Date</div>
                        <div className="col fw-bold">Type</div>
                        <div className="col fw-bold">Status</div>
                        <div className="col fw-bold">Reviewer</div>
                        {!printing && <div className="col-3 fw-bold"></div>}
                    </ListGroup.Item>
                    {assetTransactions?.map((a, i) => (
                        <ListGroup.Item key={a.assetTransactionId} as="li" className="d-flex justify-content-left align-items-start transaction-card">
                            <div className="col-1">{i + 1}</div>
                            <div className="col">{a.createdBy}</div>
                            <div className="col">{formatDate(a.date)}</div>
                            <div className="col">{a.type}</div>
                            <div className={`col ${!printing ? mapColor(a.status): ''}`}>{a.status}</div>
                            <div className="col">{a.reviewBy ?? 'N/A'}</div>
                            {!printing && <div className="col-3 d-flex justify-content-end align-items-center">
                                {a.canRevert && <Button onClick={() => triggerRevert(a.assetTransactionId)} className="btn btn-sm btn-warning fw-bold">Revert</Button>}
                                {a.isReviewable && <Button onClick={() => setReviewType(a.assetTransactionId, ReviewType.REJECT)} variant="warning" size='sm' className="mx-2 fw-bold">
                                    <Icon icon={faThumbsDown} /> {ReviewType.REJECT}
                                </Button>}
                                {a.isReviewable && <Button onClick={() => setReviewType(a.assetTransactionId, ReviewType.APPROVE)} variant="success" size='sm' className="mx-2 fw-bold">
                                    <Icon icon={faThumbsUp} /> {ReviewType.APPROVE}
                                </Button>}
                            </div>}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            }
            {(assetTransactions?.length === 0) && <AlertComponent message="No activity has been created." variant="info" />}
            <ReviewModal
                reviewAction={reviewAction}
                show={reviewAction.reviewType !== ReviewType.NONE}
                okHandler={handleUpdate}
                cancelHandler={() => setReviewType(0, ReviewType.NONE)}
            />
        </>
    );
}
