import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AppPaths } from '../appConstants';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Icon } from './Icon';
import { IconDefinition, faAdd, faBarChart, faBars, faBell, faBusinessTime, faCalendarWeek, faChartColumn, faLocation, faMoneyBill, faPeopleArrows, faPieChart, faSearch, faSignOut, faThumbsDown, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useApprovers } from '../hooks/useApprovers';
import { SpinnerComponent } from './Spinner';
import { Button, ListGroup, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';

export const NavbarComponent = () => {
    const { isAuthenticated, setUpAuth, getUser, isInRole, rCodes } = useAuth();
    const { count, isLoading } = useApprovers();
    const goTo = useNavigate();
    const isAuth = isAuthenticated();
    const [show, setShow] = useState(false)

    const handleLogout = () => {
        setUpAuth();
        goTo('/');
        window.location.reload();
    };

    const handleShow = () => setShow(false);

    const user = getUser();

    const isR20 = isInRole(rCodes?.R20);
    const isR30 = isInRole(rCodes?.R30);
    const isR40 = isInRole(rCodes?.R40);

    const isR20R30R40 = isR20 || isR30 || isR40;

    if (!isAuth) {
        return null;
    }

    const NotificationBell = () => {
        return (
            <div className="notification-bell">
                <Icon icon={faBell} /><span className="notification-count">{count}</span>
            </div>
        );
    };

    const Menu = ({ text, link, icon }: { text: string, link?: string, icon? :IconDefinition }) => {
        const isLink = !!link;
        const close = () => {
            handleShow();
            goTo(link ?? '');
        }

        return (
            <ListGroup.Item
                className={isLink ? "menu menu-item" : "menu menu-item-header"}
                action={isLink}
                onClick={isLink ? () => close() : undefined}
            >
            {!!icon && <Icon icon={icon} className='mx-3'/>}  {text}
            </ListGroup.Item>
        );
    };

    return (
        <>
            <Navbar sticky="top" expand="lg" bg="success" data-bs-theme="dark" className='d-flex justify-content-between py-3'>
                <Nav className="d-flex justify-content-start align-items-center">
                    <Button className="border border-2 border-light btn-link text-light bg-transparent text-decoration-none mx-3 btn-lg fw-bold" variant="success" onClick={() => setShow(true)}><Icon icon={faBars} /></Button>
                    <h3 className="fw-bold text-light mb-0">MOA AMS</h3>
                </Nav>
                <Nav className="d-flex justify-content-start align-items-center">
                    {isR30 && <>{isLoading ? <SpinnerComponent /> : count > 0 && <Button className="btn-link bg-transparent fw-bold mx-3 text-light" onClick={() => goTo(AppPaths.getRegistrations())}>{NotificationBell()}</Button>}</>}
                    <Button className="fs-5 btn-link bg-transparent text-decoration-none fw-bold mx-3 text-light" onClick={() => goTo(AppPaths.getProfile(user?.userId))}>{`Welcome ${user?.fullName}`} <Icon icon={faUser}/></Button>
                    <Button onClick={handleLogout} className="fs-5 btn-link bg-transparent text-decoration-none ml-3 fw-bold text-light">Sign out <Icon icon={faSignOut}/></Button>
                </Nav>
            </Navbar>
            <Offcanvas className="bg-success text-light" show={show} onHide={setShow} {...{
                name: 'Enable both scrolling & backdrop',
                scroll: true,
                backdrop: true,
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fw-bold">MOA AMS</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="px-0 offcanvas-body">
                    <ListGroup className="mb-2 rounded-0">
                        {isR40 && <>
                            <Menu text="Admin Portal" />
                            <Menu icon={faUsers} text="Users" link={AppPaths.getUsers()} />
                            <Menu icon={faPieChart} text="Categories" link={AppPaths.getCategories()} />
                            <Menu icon={faPeopleArrows} text="Custodians" link={AppPaths.getCustodians()} />
                            <Menu icon={faLocation} text="Locations" link={AppPaths.getLocations()} />
                            <Menu icon={faMoneyBill} text="Cost Centers" link={AppPaths.getCostCenters()} />
                            <Menu icon={faBusinessTime} text="Suppliers" link={AppPaths.getSuppliers()} />
                            <Menu icon={faAdd} text="Add New User" link={AppPaths.getCreateUser()} />
                            <Menu icon={faAdd} text="Add New Category" link={AppPaths.getCreateCategory()} />
                            <Menu icon={faAdd} text="Add New Custodian" link={AppPaths.getCreateCustodian()} />
                            <Menu icon={faAdd} text="Add New Location" link={AppPaths.getCreateLocation()} />
                            <Menu icon={faAdd} text="Add New Cost Center" link={AppPaths.getCreateCostCenter()} />
                            <Menu icon={faAdd} text="Add New Supplier" link={AppPaths.getCreateSupplier()} />
                        </>}
                        <Menu text="Assets Management" />
                        <Menu icon={faChartColumn} text="Dashboard" link={AppPaths.getDashboard()} />
                        <Menu icon={faBarChart} text="Reporting" link={AppPaths.getReporting()} />
                        <Menu icon={faSearch} text="Search and Filter" link={AppPaths.getSearchAndFilter()} />
                        {isR20R30R40 && <Menu icon={faCalendarWeek} text="Pending Reviews" link={AppPaths.getRegistrations()} />}
                        {isR20R30R40 && <Menu icon={faThumbsDown} text="Rejections" link={AppPaths.getRecentRejections()} />}
                        {isR20 && <Menu icon={faAdd} text="Register New Asset" link={AppPaths.getRegisterAsset()} />}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
