import { useEffect, useState } from 'react';
import { faCheck, faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AssetDetailDto, Filters } from 'appDtos';
import { Button, Modal, Form } from 'react-bootstrap';
import { AssetPaths, TransactionPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { Icon } from '../../shared/components';
import { Loading } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { filterSorter } from '../../shared/utils/utils';
import { Required } from '../../shared/components';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';

export type TransferModalProps = {
  show: boolean;
  closeHandler: (v: boolean) => void;
  asset: AssetDetailDto | null
};

export type TransferData = {
  toCustodian: string;
  toLocation: string;
}

export function TransferModal({ asset, closeHandler, show }: TransferModalProps) {
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [transfer, setTransfer] = useState<TransferData>({ toCustodian: '', toLocation: '' });
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [filters, setFilters] = useState<Filters>(() => {
    const values = localStorage.getItem(StorageKeys.APP_DATA);
    return values ? JSON.parse(values) : null;
  });

  useEffect(() => {
    if (!filters) {
      setIsLoading(true);
      (async () => {
        try {
          const res = await API.get(AssetPaths.GetFilters());
          localStorage.setItem(StorageKeys.APP_DATA, JSON.stringify(res.data));
          setFilters(res.data);
        } catch (error) {
          setError(error);
        }
        setIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (formError) setFormError('');
    if (errorMessage) setError('');

    setTransfer({ ...transfer, [name]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { toCustodian, toLocation } = transfer;
    const toLocationId = filters?.locations.find(f => f.value.trim() === toLocation.trim())?.key;
    const toCustodianId = filters?.custodians.find(f => f.value.trim() === toCustodian.trim())?.key;

    if (!toLocationId && !toCustodianId) {
      return setFormError('New custodian or new location is required.');
    }

    setIsLoading(true);

    (async () => {
      try {
        const data = {
          assetId: asset?.assetId ?? 0,
          toLocationId,
          toCustodianId,
        };
        const res = await API.post(TransactionPaths.Transfer(), data);
        setSuccessMessage(res.data.message);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  const sortedLocations = filters?.locations.filter(c => c.key !== asset?.location.locationId).sort(filterSorter) ?? [];
  const sortedCustodians = filters?.custodians.filter(c => c.key !== asset?.custodian.custodianId).sort(filterSorter) ?? [];

  const renderBody = () => {
    if (!asset || successMessage || errorMessage) {
      return null;
    }
    return (
        <Form className="border border-1 border-success p-3 rounded mb-2" noValidate onSubmit={handleSubmit} autoComplete="off">
          <Form.Group controlId="locationId" className="mt-3">
            <Form.Label className="fw-bold">New Location</Form.Label>
            <Form.Select size="lg" value={transfer.toLocation} name="toLocation" onChange={handleInputChange}>
              <option>Select new location</option>
              {sortedLocations.map(c => <option key={c.key}>{c.value}</option>)}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="custodian" className="mt-3">
            <Form.Label className="fw-bold">New Custodian</Form.Label>
            <Form.Select size="lg" value={transfer.toCustodian} name="toCustodian" onChange={handleInputChange}>
              <option>Select new custodian</option>
              {sortedCustodians.map(c => <option key={c.key}>{c.value}</option>)}
            </Form.Select>
          </Form.Group>
          <div className='text-mute mt-3'><strong><Icon icon={faInfoCircle} /> Note: </strong>To transfer an asset, you will need to provide either a new location or new custodian or both.<Required /></div>
        </Form>
    );
  };
  
  const close = () => {
    setTransfer({ toCustodian: '', toLocation: '' });
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setError('');
    if (formError) setFormError('');
    closeHandler(!!successMessage);
  };
  
  return (
    <Modal show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="border-3 border-buttom border-dark bg-success text-light">
        <Modal.Title className="fw-bold">Transfer Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertComponent message={errorMessage || formError || successMessage} variant={successMessage ? 'success' : 'danger'} />
        {loading ? <Loading /> : renderBody()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close} className="fw-bold">
          {<Icon icon={faClose} />} Close
        </Button>
        <Button disabled={loading || Boolean(successMessage)} variant="success" onClick={handleSubmit} className="fw-bold">
          <Icon icon={faCheck} /> Transfer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
