import axios from "axios";

const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('localhost') || hostname.includes('dev') || hostname.includes('055d3b10f')) {
        return 'https://moaams-dev-api2.delightfulbeach-f7fbbee8.eastus2.azurecontainerapps.io/';
    } else if (hostname.includes('www')) {
        return 'https://moaams-prod-api2.ambitiousdesert-902aa51a.eastus2.azurecontainerapps.io/';
    } else {
        //return 'http://localhost:5090'
        return 'https://localhost:7009'
    }
}

export default axios.create({
    baseURL:  getBaseUrl(),
});
