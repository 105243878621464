import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './areas/assetManagement/Home';
import { AppPaths } from './shared/appConstants';
import { Loading } from './shared/components';
import useIdleDetection from './shared/hooks/userIdleDetection';
import { useAuth } from './shared/hooks/useAuth';
import { AuthenticatedProtect, AuthorizedProtect } from './areas/auth/authMiddleware';
import { NavbarComponent } from './shared/components';
import { adminPages, assetPages, authPages } from './pages';

function App() {
  const { rCodes, setUpAuth, isAuthenticated } = useAuth();
  const isValid = isAuthenticated();
  const timer = useIdleDetection(300, isValid);

  if (timer === 0 && isValid) {
    setUpAuth();
  }

  if (!rCodes) {
    return (
      <div className="container text-center pt-5">
        <Loading />
        <div className='h5 pl-4'>Loading...</div>
      </div >);
  }

  const { R20, R30, R40 } = rCodes;

  return (
    <BrowserRouter>
      <NavbarComponent />
        <Routes>
          <Route path={AppPaths.getHome()} Component={Home} />
          <Route path="/unauthorized" Component={authPages.Unauthorized} />
          <Route path="/reset/password/*" Component={authPages.ResetPassword} />
          <Route path="/forgot-password" Component={authPages.PasswordForgetEmailSubmission} />
          
          <Route Component={AuthenticatedProtect}>
            <Route path={AppPaths.getReporting()} Component={assetPages.ReportingComponent} />
            <Route path={AppPaths.getDashboard()} Component={assetPages.ChartsComponent} />
            <Route path={AppPaths.getProfile()} Component={authPages.UserProfile} />
            <Route path={AppPaths.getAssetDetail()} Component={assetPages.AssetDetail} />
            <Route path={AppPaths.getSearchAndFilter()} Component={assetPages.Search} />
            <Route path={AppPaths.getRecentRejections()} Component={assetPages.RecentRejections} />
          </Route>

          <Route element={<AuthorizedProtect roles={[R40]} />}>
            <Route path={AppPaths.getCreateUser()} Component={adminPages.CreateUser} />
            <Route path={AppPaths.getUserDetail()} Component={adminPages.UserDetail} />
            <Route path={AppPaths.getEditUser()} Component={adminPages.EditUser} />
            <Route path={AppPaths.getUsers()} Component={adminPages.Users} />

            <Route path={AppPaths.getCreateLocation()} Component={adminPages.CreateLocation} />
            <Route path={AppPaths.getLocationDetail()} Component={adminPages.LocationDetail} />
            <Route path={AppPaths.getEditLocation()} Component={adminPages.EditLocation} />
            <Route path={AppPaths.getLocations()} Component={adminPages.Locations} />

            <Route path={AppPaths.getCreateSupplier()} Component={adminPages.CreateSupplier} />
            <Route path={AppPaths.getSupplierDetail()} Component={adminPages.SupplierDetail} />
            <Route path={AppPaths.getEditSupplier()} Component={adminPages.EditSupplier} />
            <Route path={AppPaths.getSuppliers()} Component={adminPages.Suppliers} />

            <Route path={AppPaths.getCreateCostCenter()} Component={adminPages.CreateCostCenter} />
            <Route path={AppPaths.getCostCenterDetail()} Component={adminPages.CostCenterDetail} />
            <Route path={AppPaths.getEditCostCenter()} Component={adminPages.EditCostCenter} />
            <Route path={AppPaths.getCostCenters()} Component={adminPages.CostCenters} />

            <Route path={AppPaths.getCreateCustodian()} Component={adminPages.CreateCustodian} />
            <Route path={AppPaths.getCustodianDetail()} Component={adminPages.CustodianDetail} />
            <Route path={AppPaths.getEditCustodian()} Component={adminPages.EditCustodian} />
            <Route path={AppPaths.getCustodians()} Component={adminPages.Custodians} />

            <Route path={AppPaths.getCreateCategory()} Component={adminPages.CreateCategory} />
            <Route path={AppPaths.getCategoryDetail()} Component={adminPages.CategoryDetail} />
            <Route path={AppPaths.getEditCategory()} Component={adminPages.EditCategory} />
            <Route path={AppPaths.getCategories()} Component={adminPages.Categories} />
          </Route>

          <Route element={<AuthorizedProtect roles={[R20]} />}>
            <Route path={AppPaths.getRegisterAsset()} Component={assetPages.RegisterAsset} />
            <Route path={AppPaths.getEditAsset()} Component={assetPages.EditAsset} />
          </Route>

          <Route element={<AuthorizedProtect roles={[R20, R30, R40]} />}>
            <Route path={AppPaths.getRegistrations()} Component={assetPages.PendingApprovals} />
          </Route>
            <Route path="*" Component={authPages.PageNotFound} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
