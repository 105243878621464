import React, { useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths, counties } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { SpinnerComponent } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { CreateLocationRequest } from 'appDtos';
import { Required } from '../../../shared/components';

export function CreateLocation() {
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [location, setLocation] = useState<CreateLocationRequest>({ name: '', address: '', cityOrTown: '', county: '' });
  const [formErrors, setFormError] = useState({ name: '', address: '', cityOrTown: '', county: '' });
  const goTo = useNavigate();
  const API = useApi();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (formErrors[name as keyof CreateLocationRequest]) setFormError({ ...formErrors, [name]: '' });
    if (errorMessage) setError('');

    setLocation({ ...location, [name]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const errors = { ...formErrors }
    if (!location.name) errors.name = 'Location name is required.';
    if (!location.address) errors.address = 'Address is required.';
    if (!location.cityOrTown) errors.cityOrTown = 'City or town is required.';
    if (!counties.includes(location.county)) errors.county = 'County is required.';

    if (Object.values(errors).some(v => v)) {
      return setFormError(errors);
    }

    setIsLoading(true);
    (async () => {
      try {
        await API.post(AdministrationPaths.CreateLocation(), location);
        goTo(AppPaths.getLocations())
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  return (
    <Container>
      <PageHeader pageTitle='Create Asset Location' />
      <AlertComponent message={errorMessage} />
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Location Name<Required/></Form.Label>
          <Form.Control
            autoFocus
            size="lg"
            type="text"
            name="name"
            placeholder="Enter location name."
            value={location.name}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.name)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="address" className="mt-3">
          <Form.Label className="fw-bold">Address<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="address"
            placeholder="Enter location address."
            value={location.address}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.address)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.address}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="cityOrTown" className="mt-3">
          <Form.Label className="fw-bold">City or Town<Required/></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="cityOrTown"
            placeholder="Enter location city or town."
            value={location.cityOrTown}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.cityOrTown)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.cityOrTown}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="county" className="mt-3">
          <Form.Label className="fw-bold">County<Required/></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.county)} size="lg" value={location.county} name="county" onChange={handleInputChange}>
            <option>Select county</option>
            {counties.map(c => <option key={c}>{c}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.county}</Form.Control.Feedback>
        </Form.Group>

        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>
    </Container>
  );
}
