import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/components'
import { faExclamationCircle, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CustodianDetailDto } from 'appDtos';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { AdministrationPaths } from '../../../shared/api/constants';
import { AppPaths } from '../../../shared/appConstants';
import { AlertComponent } from '../../../shared/components';
import { ConfirmationModal } from '../../../shared/components';
import { Icon } from '../../../shared/components';
import { Loading } from '../../../shared/components';
import { useApi } from '../../../shared/hooks/useApi';
import { useApiHandleError } from '../../../shared/hooks/useApiHandleError';
import { formatDate } from '../../../shared/utils/utils';

export function CustodianDetail() {
  const { id } = useParams();
  const [custodianDetail, setCustodianDetail] = useState<CustodianDetailDto | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AdministrationPaths.GetCustodianDetail(Number(id)));
        if (response.data) {
          setCustodianDetail(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteConfirmation(false);

    (async () => {
      try {
        await API.delete(AdministrationPaths.DeleteCustodian(Number(id)));
        goTo(AppPaths.getCustodians())
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    })();
  };

  const deleteModalContent = (
    <div>
      <p>Are you sure you want to delete <strong>{`${custodianDetail?.name}`}</strong>?</p>
      <p className="text-danger"> <Icon icon={faExclamationCircle} /> <strong>Important: </strong> This action cannot be undone.</p>
      <p>This action will permanently remove the selected item from the system. Any associated data or records may also be deleted.</p>
      <p>Please confirm your choice below:</p>
    </div>
  )

  return (
    <Container>
      <PageHeader pageTitle='Custodian Detail' />
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        custodianDetail ? (
          <Card className="border border-1 border-success">
            <Card.Header>
              <Card.Title className='display-5 fw-bold'>{custodianDetail.name ? custodianDetail.name : `${custodianDetail.firstName} ${custodianDetail.lastName}`}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Email</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.email ?? 'N/A'}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Custodian Type</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.type}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Phone Number</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.phoneNumber ?? 'N/A'}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Physical Address</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.address}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">City or Town</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.cityOrTown}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">County</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.county}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Created Date</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(custodianDetail.createdDate)}</Card.Title>
                </Col>
                <Col md="3">
                  <Card.Subtitle className="mt-3">Created By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.createdBy ?? 'System'}</Card.Title>
                </Col>
                {Boolean(custodianDetail.lastUpdatedDate) && <Col md="3">
                  <Card.Subtitle className="mt-3">Last Updated</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{formatDate(custodianDetail.lastUpdatedDate)}</Card.Title>
                </Col>}
                {Boolean(custodianDetail.lastUpdatedBy) && <Col md="3">
                  <Card.Subtitle className="mt-3">Last Updated By</Card.Subtitle>
                  <Card.Title className="h1 fw-bold">{custodianDetail.lastUpdatedBy}</Card.Title>
                </Col>}
              </Row>
            </Card.Body>
          </Card>
        ) : null
      )}
      {!loading && <>
        <Button onClick={() => goTo(AppPaths.getEditCustodian(Number(custodianDetail?.custodianId)))} variant="success" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faEdit} />} Edit
        </Button>
        <Button onClick={() => setShowDeleteConfirmation(true)} variant="danger" size='lg' className="mx-2 mt-5 fw-bold">
          {<Icon icon={faTrashCan} />} Delete
        </Button>
      </>}
      <ConfirmationModal
        message={deleteModalContent}
        okHandler={handleDelete}
        cancelHandler={() => setShowDeleteConfirmation(false)}
        show={showDeleteConfirmation}
      />
    </Container>
  )
}
