import { forwardRef, useEffect, useRef, useState } from 'react'
import { PageHeader } from '../../shared/components'
import { faEdit, faExclamationCircle, faFile, faPrint, faSignIn, faSignOut, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AssetDetailDto, AssetTransaction, AuditTrialEntry } from 'appDtos';
import { Container, Card, Row, Col, Button, Form, Collapse, ListGroup, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { AssetPaths, ReviewPaths, TransactionPaths } from '../../shared/api/constants';
import { AppPaths } from '../../shared/appConstants';
import { AlertComponent } from '../../shared/components';
import { Icon } from '../../shared/components';
import { Loading } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { formatDate, mapColor } from '../../shared/utils/utils';
import { ConfirmationModal, RevertApprovalModal } from '../../shared/components';
import { Required } from '../../shared/components';
import { DisposalModal } from './DisposalModal';
import { TransferModal } from './TransferModal';
import { Transactions } from './Transactions';
import { useReactToPrint } from 'react-to-print';

export function AssetDetail() {
  const { id } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDisposeModal, setShowDisposeModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [assetDetail, setAssetDetail] = useState<AssetDetailDto | null>(null);
  const [assetTransactions, setAssetTransaction] = useState<AssetTransaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [show, toggleActivities] = useState(true);
  const [printing, setPrinting] = useState(false);
  const [retirementReason, setDisposalReason] = useState('');
  const [transactionId, setTransactionId] = useState(0);
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const goTo = useNavigate();
  const [updateValues, setUpdateValues] = useState({ show: false, fetchDetail: false });
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => (componentRef as any)?.current,
  });

  function handlePrintClicked() {
    toggleActivities(true);
    setPrinting(true);
  }

  useEffect(() => {
    if (printing) {
      setTimeout(() => {
        handlePrint();
        setPrinting(false)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printing]);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(AssetPaths.GetAssetDetail(Number(id)));
        if (response.data) {
          setAssetDetail(response.data);
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!assetDetail?.assetId) return;
    (async () => {
      try {
        const res = await API.get(AssetPaths.GetTransactions(assetDetail?.assetId));
        setAssetTransaction(res.data)
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetDetail])

  useEffect(() => {
    (async () => {
      if (updateValues.fetchDetail) {
        try {
          const response = await API.get(AssetPaths.GetAssetDetail(Number(id)));
          if (response.data) {
            setAssetDetail(response.data);
            setUpdateValues({ ...updateValues, fetchDetail: false })
          }
        } catch (error) {
          setError(error)
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValues.fetchDetail, updateValues]);

  const deleteModalContent = (
    <div>
      <p>Are you sure you want to delete this asset registrations?</p>
      <p className="text-danger fw-bold"> <Icon icon={faExclamationCircle} /> <strong>Important: </strong> This action cannot be undone.</p>
      <p>This action will permanently remove the selected item from the system. Any associated data or records may also be deleted.</p>
      <p>Please confirm your choice below:</p>
    </div>
  );

  const handleDisposalOnChange = (e: any) => e.target.value.length <= 255 && setDisposalReason(e.target.value);

  const disposalContent = (
    <div>
      <p>Are you sure you want to dispose this asset?</p>
      <p>This action will change the status of this asset to "Disposed".</p>
      <p>Please enter the required "Disposal Reason" below and click Dispose or click cancel to return.</p>

      <Form.Label className="my-3 fw-bold" htmlFor="disposalReason">Disposal Reason<Required /></Form.Label>
      <Form.Control
        autoComplete='off'
        autoFocus
        as="textarea"
        placeholder='Enter disposal reason.'
        id="disposalReason"
        aria-describedby="disposalReason"
        onChange={handleDisposalOnChange} value={retirementReason}
      />
      <span className="text-muted">{`${retirementReason.length} of 255`}</span>
    </div>
  );

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteConfirmation(false);
    if (errorMessage) setError('');

    (async () => {
      try {
        await API.delete(AssetPaths.DeleteAsset(Number(id)));
        goTo(AppPaths.getHome())
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    })();
  };

  const handleDisposal = () => {
    setLoading(true);
    setShowDisposeModal(false);
    if (errorMessage) setError('');

    (async () => {
      try {
        await API.post(TransactionPaths.Dispose(), { assetId: assetDetail?.assetId, retirementReason });
        setUpdateValues({ ...updateValues, fetchDetail: true });
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  };

  const submitRevert = () => {
    setLoading(true);
    setTransactionId(0);
    if (errorMessage) setError('');

    (async () => {
      try {
        await API.post(ReviewPaths.RevertApproval(), { assetId: assetDetail?.assetId, transactionId });
        setUpdateValues({ ...updateValues, fetchDetail: true });
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  };

  const closeReviewModal = () => {
    if (showDisposeModal) setShowDisposeModal(false);
    if (retirementReason) setDisposalReason('');
  }

  const transferModalCloseHandler = (refresh: boolean) => {
    if (showTransferModal) setShowTransferModal(false);
    if (refresh) {
      setUpdateValues({ ...updateValues, fetchDetail: true });
    }
  }

  const viewDocument = (url: string) => {
    window.open(url);
  }

  return (
    <Container className="mb-3">
      <PageHeader pageTitle='Asset Detail' />
      <AlertComponent message={errorMessage} />
      {loading ? (
        <Loading />
      ) : (
        assetDetail ? (
          <>
            <Card className="noPrint border border-1 border-success">
              <Card.Header>
                <Card.Title className='display-6 fw-bold'>{assetDetail.name}</Card.Title>
                <div className="d-flex justify-content-between align-items-start" >
                  <Card.Subtitle className="fw-bold">{assetDetail.description}</Card.Subtitle>
                  <Button onClick={handlePrintClicked} variant="outline-success" size="sm" className="fw-bold"><Icon icon={faPrint} /> Print</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Code</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.code}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Category</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.category}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Serial Number</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.serialNumber}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Status</Card.Subtitle>
                    <Card.Title className={`h1 fw-bold ${mapColor(assetDetail.status ?? '')}`}>{assetDetail.status}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Condition</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.condition}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Created Date</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{formatDate(assetDetail.createdDate)}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Purchased Date</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.purchasedDate ? formatDate(assetDetail.purchasedDate) : 'N/A'}</Card.Title>
                  </Col>
                  <Col md="3">
                    <Card.Subtitle className="mt-3">Created By</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.createdBy ?? 'System'}</Card.Title>
                  </Col>
                  {Boolean(assetDetail.lastUpdatedDate) && <Col md="3">
                    <Card.Subtitle className="mt-3">Last Updated</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{formatDate(assetDetail.lastUpdatedDate)}</Card.Title>
                  </Col>}
                  {Boolean(assetDetail.lastUpdatedBy) && <Col md="3">
                    <Card.Subtitle className="mt-3">Last Updated By</Card.Subtitle>
                    <Card.Title className="h1 fw-bold">{assetDetail.lastUpdatedBy}</Card.Title>
                  </Col>}
                </Row>
                <hr />
                <Row>
                  <Col md="3">
                    <Card.Title className="h1 fw-bold mt-3">Location</Card.Title>
                    <div>{assetDetail.location.name}</div>
                    <div>{assetDetail.location.address}</div>
                    <div>{assetDetail.location.cityOrTown}</div>
                    <div>{assetDetail.location.county}</div>
                  </Col>
                  <Col md="3">
                    <Card.Title className="h1 fw-bold mt-3">Custodian</Card.Title>
                    <div>{assetDetail.custodian.name ? assetDetail.custodian.name : `${assetDetail.custodian.firstName} ${assetDetail.custodian.lastName}`}</div>
                    <div>Custodian Type: {assetDetail.custodian.type}</div>
                    <div>Email: {assetDetail.custodian.email ?? 'N/A'}</div>
                    <div>Phone: {assetDetail.custodian.phoneNumber ?? 'N/A'}</div>
                  </Col>
                  <Col md="3">
                    <Card.Title className="h1 fw-bold mt-3">Supplier</Card.Title>
                    <div>{assetDetail.supplier?.name ?? 'N/A'}</div>
                  </Col>
                  <Col md="3">
                    <Card.Title className="h1 fw-bold mt-3">Cost Center</Card.Title>
                    <div>{assetDetail.costCenter?.name ?? 'N/A'}</div>
                  </Col>
                </Row>
                <hr />
                <Card.Title className="mt-3 fw-bold">Documents</Card.Title>
                {assetDetail.documents.length ?
                  <ListGroup>
                    <ListGroup.Item key="header" as="li" className="d-flex justify-space-between align-items-baseline transaction-card">
                      <div className="col-1 fw-bold">#</div>
                      <div className="col fw-bold">Document Title</div>
                      <div className="col fw-bold">Uploaded Date</div>
                      <div className="col fw-bold">Uploaded By</div>
                      <div className="col fw-bold">Status</div>
                      <div className="col-1 fw-bold"></div>
                    </ListGroup.Item>
                    {assetDetail.documents.map((d, i) => (
                      <ListGroup.Item key={d.title} as="li" className="d-flex justify-space-between align-items-start transaction-card">
                        <div className="col-1">{i + 1}</div>
                        <div className="col">{d.title}</div>
                        <div className="col">{formatDate(d.uploadedDate)}</div>
                        <div className="col">{d.uploadedBy}</div>
                        <div className="col">{d.status}</div>
                        <div className="col-1"> <Button onClick={() => viewDocument(d.url)} variant="outline-success" className="btn btn-sm fw-bold"><Icon icon={faFile} /> View</Button></div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup> : <p>No document has been added.</p>}
                <hr />
                <div className="d-flex justify-content-between">
                  <Card.Title className="fw-bold">{show ? 'Activities' : ''}</Card.Title>
                  <Button onClick={() => toggleActivities(!show)} className="col-2 btn btn-sm btn-success fw-bold mb-1" aria-controls="activities-anel">{`${show ? 'Hide' : 'Show'} Activities Panel`}</Button>
                </div>
                <Collapse in={show}>
                  <div id="activities-panel">
                    <Transactions assetTransactions={assetTransactions} triggerRevert={(id: number) => setTransactionId(id)} refreshAssetDetail={() => setUpdateValues({ ...updateValues, fetchDetail: true })} />
                    <div className='mt-4'>Audit Trials</div>
                    <AuditTable audits={assetDetail.audits} />
                  </div>
                </Collapse>
              </Card.Body>
            </Card>


            {/* PRINT AREA */}
            <ComponentToPrint ref={componentRef}>
              <PageHeader pageTitle='MOAAMS Asset Detail' />
              <Card className="noScreen border border-1 border-success">
                <Card.Header>
                  <div className='fw-bold'>{assetDetail.name}</div>
                  <div className="d-flex justify-content-between" >
                    <div>{assetDetail.description}</div>
                  </div>
                </Card.Header>
                <Card.Body className="print-font-size">
                  <Row>
                    <Col md="3">
                      <div className="mt-1">Code</div>
                      <div className="fw-bold">{assetDetail.code}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Category</div>
                      <div className="fw-bold">{assetDetail.category}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Serial Number</div>
                      <div className="fw-bold">{assetDetail.serialNumber}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Status</div>
                      <div className="fw-bold">{assetDetail.status}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Condition</div>
                      <div className="fw-bold">{assetDetail.condition}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Created Date</div>
                      <div className="fw-bold">{formatDate(assetDetail.createdDate)}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Purchased Date</div>
                      <div className="fw-bold">{assetDetail.purchasedDate ? formatDate(assetDetail.purchasedDate) : 'N/A'}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Created By</div>
                      <div className="fw-bold">{assetDetail.createdBy ?? 'System'}</div>
                    </Col>
                    {Boolean(assetDetail.lastUpdatedDate) && <Col md="3">
                      <div className="mt-1">Last Updated</div>
                      <div className="fw-bold">{formatDate(assetDetail.lastUpdatedDate)}</div>
                    </Col>}
                    {Boolean(assetDetail.lastUpdatedBy) && <Col md="3">
                      <div className="mt-1">Last Updated By</div>
                      <div className="fw-bold">{assetDetail.lastUpdatedBy}</div>
                    </Col>}
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3">
                      <div className="mt-1">Location</div>
                      <div>{assetDetail.location.name}</div>
                      <div>{assetDetail.location.address}</div>
                      <div>{assetDetail.location.cityOrTown}</div>
                      <div>{assetDetail.location.county}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Custodian</div>
                      <div>{assetDetail.custodian.name ? assetDetail.custodian.name : `${assetDetail.custodian.firstName} ${assetDetail.custodian.lastName}`}</div>
                      <div>Custodian Type: {assetDetail.custodian.type}</div>
                      <div>Email: {assetDetail.custodian.email ?? 'N/A'}</div>
                      <div>Phone: {assetDetail.custodian.phoneNumber ?? 'N/A'}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Supplier</div>
                      <div>{assetDetail.supplier?.name ?? 'N/A'}</div>
                    </Col>
                    <Col md="3">
                      <div className="mt-1">Cost Center</div>
                      <div>{assetDetail.costCenter?.name ?? 'N/A'}</div>
                    </Col>
                  </Row>
                  <hr />
                  <div className="mt-1">Documents</div>
                  {assetDetail.documents.length ?
                    <ListGroup>
                      <ListGroup.Item key="header" as="li" className="d-flex justify-space-between align-items-baseline transaction-card">
                        <div className="col-1 fw-bold">#</div>
                        <div className="col fw-bold">Document Title</div>
                        <div className="col fw-bold">Uploaded Date</div>
                        <div className="col fw-bold">Uploaded By</div>
                        <div className="col fw-bold">Status</div>
                      </ListGroup.Item>
                      {assetDetail.documents.map((d, i) => (
                        <ListGroup.Item key={d.title} as="li" className="d-flex justify-space-between align-items-start transaction-card">
                          <div className="col-1">{i + 1}</div>
                          <div className="col">{d.title}</div>
                          <div className="col">{formatDate(d.uploadedDate)}</div>
                          <div className="col">{d.uploadedBy}</div>
                          <div className="col">{d.status}</div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup> : <p>No document has been added.</p>}
                  <hr />
                  <div>Activities</div>
                  <Transactions printing={printing} assetTransactions={assetTransactions} triggerRevert={(id: number) => setTransactionId(id)} refreshAssetDetail={() => null} />
                  <div className='mt-4'>Audit Trials</div>
                  <AuditTable audits={assetDetail.audits} isPrint />
                </Card.Body>
              </Card>
            </ComponentToPrint>
          </>
        ) : null
      )}

      {!loading && !!assetDetail && <div className="d-flex justify-content-between">
        <div>
          {Boolean(assetDetail?.config.isEditable) && <Button onClick={() => goTo(AppPaths.getEditAsset(assetDetail?.assetId))} variant="warning" size='lg' className="mx-2 mt-5 fw-bold">
            <Icon icon={faEdit} /> Edit
          </Button>}
          {Boolean(assetDetail?.config.isDeletable) && <Button onClick={() => setShowDeleteConfirmation(true)} variant="danger" size='lg' className="mx-2 mt-5 fw-bold">
            <Icon icon={faTrash} /> Delete
          </Button>}
          {Boolean(assetDetail?.config.isTransferable) && <Button onClick={() => setShowTransferModal(true)} variant="success" size='lg' className="mx-2 mt-5 fw-bold">
            <Icon icon={faSignIn} /> Transfer
          </Button>}
          {Boolean(assetDetail?.config.isRetirable) && <Button onClick={() => setShowDisposeModal(true)} variant="success" size='lg' className="mx-2 mt-5 fw-bold">
            <Icon icon={faSignOut} /> Dispose
          </Button>}
        </div>
      </div>}
      <ConfirmationModal
        message={deleteModalContent}
        okHandler={handleDelete}
        cancelHandler={() => setShowDeleteConfirmation(false)}
        show={showDeleteConfirmation}
      />
      <RevertApprovalModal
        okHandler={submitRevert}
        cancelHandler={() => setTransactionId(0)}
        show={transactionId > 0}
      />
      <DisposalModal
        disableButton={(showDisposeModal && !retirementReason)}
        show={showDisposeModal}
        body={disposalContent}
        okHandler={handleDisposal}
        cancelHandler={closeReviewModal}
      />
      <TransferModal
        show={showTransferModal}
        asset={assetDetail}
        closeHandler={transferModalCloseHandler}
      />
    </Container>
  );
}


function AuditTable({ audits, isPrint }: { isPrint?: boolean, audits: AuditTrialEntry[] }) {
  return (
    <Table striped bordered size='sm' className={isPrint ? 'printClass' : 'noprintClass'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Action</th>
          <th>User</th>
          <th>Additional Information</th>
        </tr>
      </thead>
      <tbody>
        {audits.map((a, i) => <tr key={a.auditTrialEntryId}>
          <td>{i + 1}</td>
          <td>{new Date(a.dateTime).toLocaleDateString()} at {new Date(a.dateTime).toLocaleTimeString()}</td>
          <td>{a.action}</td>
          <td>{a.user}</td>
          <td>{a.additionalInfo}</td>
        </tr>)}
      </tbody>
    </Table>
  );
}


export const ComponentToPrint = forwardRef<HTMLDivElement, any>(({ children }, ref) => {
  return <div ref={ref} className="noScreen bg-light p-4">
    {children}
  </div>
})
