import { useEffect, useState } from 'react'
import { PageHeader } from '../../shared/components'
import { faCheck, faFile, faTrashCan, faUndo, faUpload } from '@fortawesome/free-solid-svg-icons';
import { AssetDetailDto, DocumentDto, Filters } from 'appDtos';
import { Container, Button, Form, ListGroup } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetPaths, TransactionPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { Icon } from '../../shared/components';
import { Required } from '../../shared/components';
import { SpinnerComponent } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AppPaths } from '../../shared/appConstants';
import { BackButton } from '../../shared/components';
import { Loading } from '../../shared/components';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { filterSorter, formatDate } from '../../shared/utils/utils';
import { FileType, FileUploadModal } from './FileUploadModal';
import { SingleDatePickerComponent } from '../../shared/components/DateRangePicker';

type Data = {
  assetId: number;
  name: string;
  description: string;
  serialNumber: string;
  code: string;
  condition: string;
  category: string;
  purchasedDate: Date | null;
  costCenter: string;
  supplier: string;
}

interface ExistingDocType extends DocumentDto {
  isRemoved: boolean
}

export function EditAsset() {
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [existingDocs, setExistingDocs] = useState<ExistingDocType[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const { id } = useParams();
  const goTo = useNavigate();
  const API = useApi();
  const [filters, setFilters] = useState<Filters>(() => {
    const values = localStorage.getItem(StorageKeys.APP_DATA);
    return values ? JSON.parse(values) : null;
  });
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(true);
  const [asset, setAsset] = useState<Data>({
    assetId: Number(id),
    name: '',
    description: '',
    serialNumber: '',
    code: '',
    condition: '',
    category: '',
    costCenter: '',
    purchasedDate: null,
    supplier: '',
  });
  const [formErrors, setFormError] = useState({
    assetId: 0,
    name: '',
    description: '',
    serialNumber: '',
    code: '',
    condition: '',
    category: '',
    costCenter: '',
    purchasedDate: '',
    supplier: '',
    files: ''
  });

  useEffect(() => {
    if (!filters) {
      (async () => {
        try {
          const res = await API.get(AssetPaths.GetFilters());
          localStorage.setItem(StorageKeys.APP_DATA, JSON.stringify(res.data));
          setFilters(res.data);
        } catch (error) {
          setError(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    (async () => {
      if (filters) {
        try {
          const response = await API.get<AssetDetailDto>(AssetPaths.GetAssetDetailLite(Number(id)));
          if (response.data) {
            const { documents, assetId, name, description, code, purchasedDate, serialNumber, condition, category, costCenter, supplier } = response.data;
            setAsset({
              assetId,
              name,
              description: description as string,
              code,
              serialNumber,
              condition,
              purchasedDate: purchasedDate ? new Date(purchasedDate) : null,
              category: filters?.categories.find(f => f.value === category)?.value.trim() ?? '',
              costCenter: filters?.costCenters.find(f => f.key === costCenter?.costCenterId)?.value.trim() ?? '',
              supplier: filters?.suppliers.find(f => f.key === supplier?.supplierId)?.value.trim() ?? '',
            });
            setExistingDocs(documents.map(d => ({ ...d, isRemoved: d.status === 'Removed' })));
          }
        } catch (error) {
          setError(error)
        }
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (formErrors[name as keyof Data]) setFormError({ ...formErrors, [name]: '' });
    if (errorMessage) setError('');

    setAsset({ ...asset, [name]: value });
  };

  const removeExistingDoc = (title: string) => {
    setExistingDocs(existingDocs.map(d => d.title !== title ? d : ({ ...d, isRemoved: !d.isRemoved })));
    setFiles(files.filter(f => f.title !== title));
  };
  const removeFile = (title: string) => {
    setFiles(files.filter(f => f.title !== title));
  };

  const handleModalAction = (file?: FileType) => {
    if (formErrors.files) setFormError({ ...formErrors, files: '' });
    if (file) {
      setFiles([file, ...files]);
    };
    setShowFileUploadModal(false);
  }

  const viewDocument = (url: string) => {
    window.open(url);
  }

  const handleSubmit = (event: any) => {
    if (errorMessage) setError('');
    event.preventDefault();
    const { assetId, name, description, code, purchasedDate, serialNumber, condition, category, costCenter, supplier } = asset;

    const categoryId = filters?.categories.find(f => f.value.trim() === category.trim())?.key;
    const costCenterId = filters?.costCenters.find(f => f.value.trim() === costCenter.trim())?.key;
    const supplierId = filters?.suppliers.find(f => f.value.trim() === supplier.trim())?.key;

    const errors = { ...formErrors };

    if (!name) errors.name = 'Asset name is required.';
    if (!serialNumber) errors.serialNumber = 'Serial Number is required.';
    if (!code) errors.code = 'Asset Code is required.';
    if (!condition) errors.condition = 'Asset Condition is required.';
    if (!categoryId) errors.category = 'Asset Category is required.';
    if (!existingDocs.some(d => !d.isRemoved) && files.length === 0) errors.files = 'Asset Document is required.';

    if (Object.values(errors).some(v => v)) {
      return setFormError(errors);
    }

    (async () => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('assetId', assetId.toString())
        formData.append('name', name)
        formData.append('description', description)
        formData.append('code', code)
        purchasedDate && formData.append('purchasedDate', purchasedDate.toISOString())
        formData.append('serialNumber', serialNumber)
        formData.append('condition', filters?.conditions.find(f => f.value.trim() === condition.trim())?.key.toString() ?? '0')
        formData.append('categoryId', categoryId?.toString() ?? '0')
        formData.append('costCenterId', costCenterId?.toString() ?? '0')
        formData.append('supplierId', supplierId?.toString() ?? '0')

        existingDocs.forEach((doc, index) => {
          formData.append(`existingDocuments[${index}].documentId`, doc.documentId.toString());
          formData.append(`existingDocuments[${index}].isRemoved`, doc.isRemoved ? 'true' : 'false');
          formData.append(`existingDocuments[${index}].title`, doc.title);
        });

        files.forEach((file, index) => {
          formData.append(`newDocuments[${index}].title`, file.title);
          formData.append(`newDocuments[${index}].file`, file.content);
        });

        await API.post(TransactionPaths.Update(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        goTo(AppPaths.getAssetDetail(assetId))
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  const sortedConditions = filters?.conditions.filter(c => ['Good', 'Damaged', 'Fair'].includes(c.value)).sort(filterSorter) ?? [];
  const sortedCostCenters = filters?.costCenters.sort(filterSorter) ?? [];
  const sortedCategories = filters?.categories.sort(filterSorter) ?? [];
  const sortedSuppliers = filters?.suppliers.sort(filterSorter) ?? [];

  return (
    <Container className="mb-3">
      <PageHeader pageTitle='Update Asset' />
      <AlertComponent message={errorMessage} />
      {loading ? <Loading /> : <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <Form.Group controlId="name" className="mt-3">
          <Form.Label className="fw-bold">Asset Name<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="name"
            placeholder="Enter asset name."
            value={asset.name}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.name)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="description" className="mt-3">
          <Form.Label className="fw-bold">Description</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="description"
            placeholder="Enter asset description."
            value={asset.description}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.description)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="code" className="mt-3">
          <Form.Label className="fw-bold">Code<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="code"
            placeholder="Enter asset code."
            value={asset.code}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.code)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.code}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="serialNumber" className="mt-3">
          <Form.Label className="fw-bold">Serial Number<Required /></Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="serialNumber"
            placeholder="Enter asset serial number."
            value={asset.serialNumber}
            onChange={handleInputChange}
            isInvalid={Boolean(formErrors.serialNumber)}
          />
          <Form.Control.Feedback type="invalid">{formErrors.serialNumber}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="categoryId" className="mt-3">
          <Form.Label className="fw-bold">Category<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.category)} size="lg" value={asset.category} name="category" onChange={handleInputChange}>
            <option>Select Category</option>
            {sortedCategories.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.category}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="condition" className="mt-3">
          <Form.Label className="fw-bold">Condition<Required /></Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.condition)} size="lg" value={asset.condition} name="condition" onChange={handleInputChange}>
            <option>Select Condition</option>
            {sortedConditions.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.condition}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="costCenterId" className="mt-3">
          <Form.Label className="fw-bold">Cost Center</Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.costCenter)} size="lg" value={asset.costCenter} name="costCenter" onChange={handleInputChange}>
            <option>Select Cost Center</option>
            {sortedCostCenters.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.costCenter}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="supplierId" className="mt-3">
          <Form.Label className="fw-bold">Supplier</Form.Label>
          <Form.Select isInvalid={Boolean(formErrors.supplier)} size="lg" value={asset.supplier} name="supplier" onChange={handleInputChange}>
            <option>Select supplier</option>
            {sortedSuppliers.map(c => <option key={c.key}>{c.value}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formErrors.supplier}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="code" className="mt-3">
          <Form.Label className="fw-bold">Purchased Date</Form.Label><br />
          <SingleDatePickerComponent date={asset.purchasedDate} setDate={(date) => setAsset({ ...asset, purchasedDate: date })} />
        </Form.Group>

        <div className="d-flex justify-content-between my-3">
          <Form.Label className="fw-bold">Existing Documents</Form.Label>
        </div>
        {existingDocs.length ?
          <ListGroup>
            <ListGroup.Item key="header" as="li" className="d-flex justify-space-between align-items-baseline transaction-card">
              <div className="col-1 fw-bold">#</div>
              <div className="col fw-bold">Document Title</div>
              <div className="col fw-bold">Uploaded Date</div>
              <div className="col fw-bold">Uploaded By</div>
              <div className="col fw-bold">Status</div>
              <div className="col-2 fw-bold"></div>
            </ListGroup.Item>
            {existingDocs.map((d, i) => (
              <ListGroup.Item key={d.title} as="li" className="d-flex justify-space-between align-items-start transaction-card">
                <div className="col-1">{i + 1}</div>
                <div className="col">{d.isRemoved ? <del>{d.title}</del> : d.title}</div>
                <div className="col">{d.isRemoved ? <del>{formatDate(d.uploadedDate)}</del> : formatDate(d.uploadedDate)}</div>
                <div className="col">{d.isRemoved ? <del>{d.uploadedBy}</del> : d.uploadedBy}</div>
                <div className="col">{d.status}</div>
                <div className="col-2">
                  <Button onClick={() => removeExistingDoc(d.title)} title="Mark this document to be removed." variant={d.isRemoved ? 'outline-success' : 'outline-danger'} className="btn btn-sm fw-bold mx-4 edit-doc-remove-button"><Icon icon={d.isRemoved ? faUndo : faTrashCan} /> {d.isRemoved ? 'Undo' : 'Remove'}</Button>
                  <Button onClick={() => viewDocument(d.url)} title="Click to view document in a new tab." variant="outline-success" className="btn btn-sm fw-bold"><Icon icon={faFile} /> View</Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup> : <p>No existing documents.</p>}
        <hr />
        <div className="d-flex justify-content-between my-3">
          <Form.Label className="fw-bold">New Documents</Form.Label>
          <Button className="fw-bold" variant="outline-success" onClick={() => setShowFileUploadModal(true)}>
            <Icon icon={faUpload} /> Add Document
          </Button>
        </div>
        {files.length ?
          <ListGroup>
            <ListGroup.Item key="header" as="li" className="d-flex justify-space-between align-items-baseline transaction-card">
              <div className="col-1 fw-bold">#</div>
              <div className="col fw-bold">Document Title</div>
              <div className="col fw-bold">File</div>
              <div className="col-1 fw-bold">Action</div>
            </ListGroup.Item>
            {files?.map((f, i) => (
              <ListGroup.Item key={f.title} as="li" className="d-flex justify-space-between align-items-start transaction-card">
                <div className="col-1">{i + 1}</div>
                <div className="col">{f.title}</div>
                <div className="col">{f.content.name}</div>
                <div className="col-1"> <Button onClick={() => removeFile(f.title)} variant="outline-danger" title="Remove this file from selection." className="btn btn-sm fw-bold"><Icon icon={faTrashCan} /> Remove</Button></div>
              </ListGroup.Item>
            ))}
          </ListGroup> : (Boolean(formErrors.files) ? <p className="text-danger">{formErrors.files}</p> : <p>No new document has been selected.</p>)}

        <BackButton />

        <Button variant="success" type="submit" size='lg' className="mx-2 mt-5 fw-bold">
          {loading ? <SpinnerComponent /> : <Icon icon={faCheck} />} Submit
        </Button>
      </Form>}
      <FileUploadModal
        show={showFileUploadModal}
        files={[...files, ...existingDocs.filter(d => !d.isRemoved).map(d => ({ title: d.title, content: '' }))]}
        handleModalAction={handleModalAction}
      />
    </Container>
  );
}
