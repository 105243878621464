import { useEffect, useRef, useState } from 'react'
import { Button, Form, Container, Card } from 'react-bootstrap';
import { Icon } from '../../shared/components';
import { faFile, faPrint, faRefresh, faRemove } from '@fortawesome/free-solid-svg-icons';
import { useApi } from '../../shared/hooks/useApi';
import Table from 'react-bootstrap/Table';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AssetPaths, DashboardPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { Filter, Filters, Report, SearchValues, TitleCountPair } from 'appDtos';
import { DatePickerComponent, DateRange } from '../../shared/components';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { filterSorter } from '../../shared/utils/utils';
import { ComponentToPrint } from './Detail';
import { useReactToPrint } from 'react-to-print';

type ReportParams = {
  category: string;
  custodian: string;
  location: string;
  status: string;
  condition: string;
  supplier: string;
  costCenter: string;
  createdFrom: Date | null,
  createdTo: Date | null,
}

export function ReportingComponent() {
  const API = useApi();
  const [report, setReport] = useState<Report>();
  const [loading, setLoading] = useState(false);
  const [showConfiguration, setShowConfiguration] = useState(true);
  const { setError, errorMessage } = useApiHandleError();
  const [filters, setFilters] = useState<Filters>(() => {
    const values = localStorage.getItem(StorageKeys.APP_DATA);
    return values ? JSON.parse(values) : null;
  });
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => (componentRef as any)?.current,
  });

  const [params, setParams] = useState<ReportParams>(() => {
    const search = window.location.search;

    if (!search) {
      return {
        createdFrom: null,
        createdTo: null,
        category: '',
        custodian: '',
        location: '',
        costCenter: '',
        supplier: '',
        status: '',
        condition: '',
      } as ReportParams;
    }

    const urlSearchParams = new URLSearchParams(search);
    const values: any = Object.fromEntries(urlSearchParams.entries());
    values.createdFrom = values.createdFrom && new Date(values.createdFrom);
    values.createdTo = values.createdTo && new Date(values.createdTo);
    return values;
  });

  const total = report?.cards.find(c => c.entityType === 'all');
  const statuses = report?.cards.filter(c => c.entityType === 'status') ?? [];
  const conditions = report?.cards.filter(c => c.entityType === 'condition') ?? [];

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value || null });
  };

  const setDateRange = ({ from, to }: DateRange) => {
    setParams({ ...params, createdFrom: from, createdTo: to });
  };

  const handleValueUnSelected = (key: keyof SearchValues) => {
    if (key === 'createdFrom' || key === 'createdTo') {
      return setParams({ ...params, [key]: null });
    }
    setParams({ ...params, [key]: '' });
  };

  useEffect(() => {
    if (!filters) {
      (async () => {
        try {
          const res = await API.get(AssetPaths.GetFilters());
          localStorage.setItem(StorageKeys.APP_DATA, JSON.stringify(res.data));
          setFilters(res.data);
        } catch (error) {
          setError(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const v = {
      ...params,
      createdFrom: params.createdFrom ? params.createdFrom.toISOString() : '',
      createdTo: params.createdTo ? params.createdTo.toISOString() : '',
    };
    const url = Object.entries(v).filter(([, v]) => v).reduce((r, [k, v]) => r ? `${r}&${k}=${v}` : `/reporting?${k}=${v}`, '');
    if (url) {
      return window.history.replaceState(null, '', url);
    }
    return window.history.replaceState(null, '', "/reporting");
  }, [params])

  const handleSubmit = () => {
    if (errorMessage) setError('');
    setLoading(true);
    (async () => {
      try {
        const { createdFrom, createdTo, condition, category, costCenter, custodian, location, status, supplier } = params;
        const data = {
          createdFrom,
          createdTo,
          condition: filters?.conditions.find(c => c.value === condition)?.key ?? 0,
          status: filters?.statuses.find(c => c.value === status)?.key ?? 0,
          categoryId: filters?.categories.find(c => c.value === category)?.key ?? 0,
          custodianId: filters?.custodians.find(c => c.value === custodian)?.key ?? 0,
          locationId: filters?.locations.find(c => c.value === location)?.key ?? 0,
          supplierId: filters?.suppliers.find(c => c.value === supplier)?.key ?? 0,
          costCenterId: filters?.costCenters.find(c => c.value === costCenter)?.key ?? 0
        };
        const response = await API.post(DashboardPaths.GenerateReport(), data);
        setReport(response.data);
        setShowConfiguration(false);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    })();
  };

  const handleClear = () => {
    setParams({
      category: '',
      custodian: '',
      location: '',
      status: '',
      condition: '',
      supplier: '',
      costCenter: '',
      createdFrom: null,
      createdTo: null,
    })
  }

  const { createdFrom, createdTo, condition, category, costCenter, custodian, location, status, supplier } = params;
  const selected = Object.entries({
    createdFrom: createdFrom ? createdFrom.toLocaleDateString() : '',
    createdTo: createdTo ? createdTo.toLocaleDateString() : '',
    condition,
    category,
    costCenter,
    custodian,
    location,
    status,
    supplier
  }).filter(([, v]) => v);

  const sortedConditions = filters?.conditions.filter((c: Filter) => ['Good', 'Damaged','Fair'].includes(c.value)).sort(filterSorter) ?? [];
  const sortedLocations = filters?.locations.sort(filterSorter) ?? [];
  const sortedCustodians = filters?.custodians.sort(filterSorter) ?? [];
  const sortedCostCenters = filters?.costCenters.sort(filterSorter) ?? [];
  const sortedCategories = filters?.categories.sort(filterSorter) ?? [];
  const sortedSuppliers = filters?.suppliers.sort(filterSorter) ?? [];
  const sortedStatuses = filters?.statuses.filter(v => v.value !== 'None').sort(filterSorter) ?? [];

  const renderSelected = (isPrint: boolean = false) => {
    const classNames = isPrint ? 'print-font-size py-0' : '';


    return selected.length > 0 &&
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th className={classNames}>Field</th>
            <th className={classNames + ' col'}>Value</th>
            <th className="col fw-bold noPrint">{selected.length > 1 && <Button variant="outline-danger" title="Click to remove all." className="btn-sm m-1 fw-bold" onClick={() => handleClear()}><Icon icon={faRemove} /> Clear all</Button>}</th>
          </tr>
        </thead>
        <tbody>
          {
            selected.map(([k, v]) =>
              <tr key={k}>
                <td className={classNames}>{k.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase())}</td>
                <td className={classNames + ' col'}>{v}</td>
                <td className="col noPrint"><Button variant="outline-danger" title="Click to remove." className="btn-sm m-1 fw-bold" onClick={() => handleValueUnSelected(k as any)}><Icon icon={faRemove} /></Button></td>
              </tr>)
          }
        </tbody>
      </Table>
  }

  const date = new Date();
  return (
    <>
      <Container className="mb-5 noPrint">
        <div className="page-header my-3 p-3 fw-bold rounded border border-1 border-success text-success d-flex justify-content-between align-items-center">
          <h1 className="h3 mb-0">Assets Report</h1>
          <div>
            {!showConfiguration && <Button className="fw-bold mx-2" title="Click to go back to configuration." onClick={() => setShowConfiguration(true)} variant="outline-success"><Icon icon={faFile} /> Setup Report</Button>}
            <Button className="fw-bold" title="Click to generate report." disabled={loading} onClick={handleSubmit} variant="outline-success"><Icon className={loading ? 'refreshing' : ''} icon={faRefresh} /> {loading ? 'Generating...' : 'Generate Report'}</Button>
            {!showConfiguration && Boolean(total?.count) && <Button onClick={handlePrint} variant="outline-success" className="fw-bold mx-2"><Icon icon={faPrint} /> Print</Button>}
          </div>
        </div>
        <AlertComponent message={errorMessage} />
        {Boolean(selected.length) && <div className="h6 fw-bold">Report Criteria</div>}
        {renderSelected()}
        {showConfiguration && <Card>
          <Card.Body className="p-5">
            <Card.Title className="mb-0">Setup Report</Card.Title>
            <hr />
            <Form.Group controlId="dateRange">
              <Form.Label className="fw-bold">Asset Created Date Range</Form.Label>
              <DatePickerComponent setDate={setDateRange} dateRange={{ from: params.createdFrom, to: params.createdTo }} />
            </Form.Group>
            <Form.Group controlId="status" className="mt-3">
              <Form.Label className="fw-bold">Status</Form.Label>
              <Form.Select value={params.status} name="status" onChange={handleInputChange}>
                <option value="">Select Status</option>
                {sortedStatuses.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="category" className="mt-3">
              <Form.Label className="fw-bold">Category</Form.Label>
              <Form.Select value={params.category} name="category" onChange={handleInputChange}>
                <option value="">Select Category</option>
                {sortedCategories.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="condition" className="mt-3">
              <Form.Label className="fw-bold">Condition</Form.Label>
              <Form.Select value={params.condition} name="condition" onChange={handleInputChange}>
                <option value="">Select Condition</option>
                {sortedConditions.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="location" className="mt-3">
              <Form.Label className="fw-bold">Location</Form.Label>
              <Form.Select value={params.location} name="location" onChange={handleInputChange}>
                <option value="">Select Location</option>
                {sortedLocations.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="custodian" className="mt-3">
              <Form.Label className="fw-bold">Custodian</Form.Label>
              <Form.Select value={params.custodian} name="custodian" onChange={handleInputChange}>
                <option value="">Select custodian</option>
                {sortedCustodians.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="costCenter" className="mt-3">
              <Form.Label className="fw-bold">Cost Center</Form.Label>
              <Form.Select value={params.costCenter} name="costCenter" onChange={handleInputChange}>
                <option value="">Select Cost Center</option>
                {sortedCostCenters.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="supplier" className="mt-3">
              <Form.Label className="fw-bold">Supplier</Form.Label>
              <Form.Select value={params.supplier} name="supplier" onChange={handleInputChange}>
                <option value="">Select supplier</option>
                {sortedSuppliers.map((c: Filter) => <option key={c.key}>{c.value}</option>)}
              </Form.Select>
            </Form.Group>
          </Card.Body>
        </Card>}
        <div className="h6 mt-4 fw-bold">Report Detail</div>
        <hr />
        {!showConfiguration && <div className="report-container">
          {!!total && <div className="">
            <div className="mt-3 mb-0">Total Assets: {total?.count}</div>
          </div>}

          <ReportTable pairs={statuses} title='Statuses' />
          <ReportTable pairs={conditions} title='Conditions' />
          {report?.lists.map((l, i) => (<ReportTable pairs={l.data} title={l.title} key={i} />))}
        </div>}
      </Container>


      <ComponentToPrint ref={componentRef}>
        <Container className="mb-2 noScreen">
          <div className="my-3 p-3 fw-bold rounded border border-1 text-center">
            <h5 className="mb-0">Ministry of Agriculture (MOA)</h5>
            <h6 className="mb-0">Assets Management System Report</h6>
            <div className="mb-0">{date.toLocaleString('en-us', { weekday: 'long' })}, {date.toLocaleString('en-us', { month: 'long' })} {date.getDate()}, {date.getUTCFullYear()}</div>
          </div>
          {Boolean(selected.length) && <div className="h6">Report Criteria</div>}
          {renderSelected(true)}
          <div className="h6">Report Detail</div>
          <hr className="my-0"/>
          {!showConfiguration && <div className="report-container">
            {!!total && <div className="">
              <div className="mt-1 mb-0">Total Assets: {total?.count}</div>
            </div>}
            <ReportTable pairs={statuses} title='Statuses' isPrint />
            <ReportTable pairs={conditions} title='Conditions' isPrint />
            {report?.lists.map((l, i) => (<ReportTable pairs={l.data} title={l.title} key={i} isPrint />))}
          </div>}
        </Container>
      </ComponentToPrint>
    </>
  )
}



function ReportTable({ pairs, title, isPrint = false }: { pairs: TitleCountPair[], title: string, isPrint?: boolean }) {
  if (!pairs.length) {
    return null;
  }

  const classNames = isPrint ? 'print-font-size py-0' : '';

  return (
    <Table striped bordered size="sm" className="my-1">
      <thead>
        <tr>
          <th className={classNames}>{title}</th>
          <th className={classNames + ' col-3'}>Count</th>
        </tr>
      </thead>
      <tbody>
        {pairs.map(p => <tr key={p.title}>
          <td className={classNames}>{p.title}</td>
          <td className={classNames + ' col-3'}>{p.count}</td>
        </tr>)}
      </tbody>
    </Table>
  );
}
