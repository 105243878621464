import { ILoginResponse } from 'appDtos';
import Card from 'react-bootstrap/Card';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, InputGroup } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import useFormProcessor from '../../shared/hooks/userForm';
import { isValidEmail } from '../../shared/utils/validators';
import { faEye, faEyeSlash, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../shared/components';
import { useApi } from '../../shared/hooks/useApi';
import { AccountPaths } from '../../shared/api/constants';
import { AlertComponent } from '../../shared/components';
import { SpinnerComponent } from '../../shared/components';

export interface LoginProps {
  setUpAuth: (loginResponse: ILoginResponse) => void;
}

export interface ILoginRequest {
  email: string;
  password: string;
}

export const LoginForm = ({ setUpAuth }: LoginProps) => {
  const API = useApi();
  const [formErrorMessage, setFormError] = React.useState('');
  const { errorMessage, setError } = useApiHandleError('login');
  const [show, setShow] = React.useState(false);
  const goto = useNavigate();
  const {
    handleOnChange,
    setIsProcessing,
    formData,
    isProcessing,
  } = useFormProcessor<ILoginRequest>({ email: '', password: '' }, { email: true, password: true });

  React.useEffect(() => setFormError(''), [formData]);

  const handleFormChange = (e: any) => {
    if (errorMessage) {
      setError('');
    }
    handleOnChange({ [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!isValidEmail(formData.email)) return setFormError('Email is invalid.');
    if (!formData.password.trim()) return setFormError('Password is required.');

    setIsProcessing(true);

    try {
      const result = await API.post<ILoginResponse>(AccountPaths.Login(), formData);
      if (result?.data) {
        setUpAuth(result.data);
        return goto('/dashboard');
      }
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const toggleShow = () => setShow(!show);

  return (
    <Card className="bg-dark text-light rounded-0 login-image">
      <div className="m-5 fw-bold p-3 border-start border-bottom border-5 border-light">
        <div className="display-5 fw-bold">Ministry of Agriculture</div>
        <div className="h4 fw-bold">Assets Management System</div>
      </div>
      <div className="d-flex justify-content-start px-5">
        <Col xxl="4" lg="4" md="4" sm="12" xs="12">
          <div className="h5 fw-bold mt-3">Login</div>
          <AlertComponent message={formErrorMessage || errorMessage} />
          <form onSubmit={handleSubmit}>
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3 text-dark"
            >
              <Form.Control
                size="lg"
                onChange={handleFormChange}
                type="email"
                placeholder="Enter email address"
                name="email"
                autoFocus
                required />
            </FloatingLabel>
            <InputGroup className="mb-3">
              <FloatingLabel
                controlId="floatingPassword"
                label="Password"
                className="text-dark">
                <Form.Control
                  size="lg"
                  onChange={handleFormChange}
                  value={formData.password}
                  placeholder="Enter password"
                  type={show ? "text" : "password"}
                  name="password"
                  autoComplete="off"
                  required />
              </FloatingLabel>
              <Button onClick={toggleShow} variant="link" className="bg-light text-dark fw-bold border-0 text-success text-decoration-none"> <Icon icon={show ? faEyeSlash : faEye} /></Button>
            </InputGroup>
            <Link to="/forgot-password" className="btn btn-md btn-link p-0 text-light fw-bold text-decoration-none">Forgot password?</Link>
            <Button
              type='submit'
              className='w-100 mt-3 mb-5 fw-bold p-3'
              size="lg"
              variant="outline-light"
              disabled={isProcessing || Boolean(formErrorMessage || errorMessage)}
            >
              {!isProcessing && <span className="mr-2"><Icon icon={faSignIn} /></span>}
              {isProcessing && <SpinnerComponent />}  {isProcessing ? 'Signing you in' : 'Sign in'}
            </Button>
          </form>
        </Col>
      </div>
      <div className='text-light px-5'>
        <div>This application was built and is maintained by</div>
        <div className='fw-bold'>INFOZY, Inc. </div>
        <div>Monrovia, Liberia</div>
        <div>Contact: inquiry@infozyinc.com</div>
      </div>
    </Card>
  );
}
