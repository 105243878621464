import { useEffect, useState } from 'react'
import { PageHeader } from '../../shared/components'
import { Container, InputGroup, Form, Button } from 'react-bootstrap';
import { SearchValues, SearchResultDto, Filters } from 'appDtos';
import { AssetPaths } from '../../shared/api/constants';
import { useApi } from '../../shared/hooks/useApi';
import { useApiHandleError } from '../../shared/hooks/useApiHandleError';
import { AlertComponent } from '../../shared/components';
import { Loading } from '../../shared/components';
import { Icon } from '../../shared/components';
import { faClose, faList, faMagnifyingGlass, faTable } from '@fortawesome/free-solid-svg-icons';
import { FiltersModal } from '../../shared/components';
import { AssetResultCard, AssetResultTable } from './AssetResultCard';
import { StorageKeys } from '../../shared/hooks/useLocalStorage';
import { DateRange } from '../../shared/components';
import { SelectedFilter } from './SelectedFilter';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Search() {
  const [open, setOpen] = useState(false);
  const [isTableView, setIsTableView] = useState(() => {
    const view = localStorage.getItem(StorageKeys.SEARCH_TABLE_VIEW);
    return view ? JSON.parse(view) : false;
  });
  const API = useApi();
  const { setError, errorMessage } = useApiHandleError();
  const [loading, setIsLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResultDto>({ results: [], currentPage: 0, pages: 0 });
  const [filters, setFilters] = useState<Filters>(() => {
    const values = localStorage.getItem(StorageKeys.APP_DATA);
    return values ? JSON.parse(values) : null;
  });
  const [searchValues, setSearchValues] = useState<SearchValues>(() => {
    const search = window.location.search;
    const defaultV = {
      createdFrom: null,
      createdTo: null,
      searchTerm: '',
      pageNumber: 1,
    }
    if (!search) {
      return {
        ...defaultV,
        category: '',
        custodian: '',
        location: '',
        costCenter: '',
        supplier: '',
        status: '',
        condition: '',
      } as SearchValues;
    }

    const urlSearchParams = new URLSearchParams(search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    params.createdFrom = params.createdFrom && new Date(params.createdFrom);
    params.createdTo = params.createdTo && new Date(params.createdTo);
    return { ...params, ...defaultV } as SearchValues;
  });

  const handlePaginate = (pageNumber: number) => setSearchValues({ ...searchValues, pageNumber });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSearchValues({ ...searchValues, [name]: value || null });
  };

  const handleViewChange = () => {
    const view = !isTableView;
    localStorage.setItem(StorageKeys.SEARCH_TABLE_VIEW, `${view}`);
    setIsTableView(view);
  };

  const setDateRange = ({ from, to }: DateRange) => {
    setSearchValues({ ...searchValues, createdFrom: from, createdTo: to });
  };

  const handleValueUnSelected = (key: keyof SearchValues) => {
    if (key === 'createdFrom' || key === 'createdTo') {
      setSearchValues({ ...searchValues, [key]: null });
    }else{
      setSearchValues({ ...searchValues, [key]: '' });
    }
    setShouldSearch(true);
  };

  useEffect(() => {
    filters &&  setShouldSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    setShouldSearch(true);
  }, [searchValues.pageNumber])

  useEffect(() => {
    if (shouldSearch) {
      handleSubmit();
      setShouldSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSearch])

  useEffect(() => {
    if (!filters) {
      (async () => {
        try {
          const res = await API.get(AssetPaths.GetFilters());
          localStorage.setItem(StorageKeys.APP_DATA, JSON.stringify(res.data));
          setFilters(res.data);
        } catch (error) {
          setError(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const v = {
      ...searchValues,
      createdFrom: searchValues.createdFrom ? searchValues.createdFrom.toISOString() : '',
      createdTo: searchValues.createdTo ? searchValues.createdTo.toISOString() : '',
    };
    const url = Object.entries(v).filter(([, v]) => v).reduce((r, [k, v]) => r ? `${r}&${k}=${v}` : `/search-and-filter?${k}=${v}`, '');
    if (url) {
      return window.history.replaceState(null, '', url);
    }
    return window.history.replaceState(null, '', "/search-and-filter");
  }, [searchValues])

  const handleSubmit = () => {
    if (errorMessage) setError('');
    setIsLoading(true);
    (async () => {
      try {
        const { searchTerm, createdFrom, createdTo, pageNumber, condition, category, costCenter, custodian, location, status, supplier } = searchValues;
        const data = {
          searchTerm,
          createdFrom,
          createdTo,
          pageNumber,
          condition: filters?.conditions?.find(c => c.value === condition)?.key ?? 0,
          status: filters?.statuses?.find(c => c.value === status)?.key ?? 0,
          categoryId: filters?.categories?.find(c => c.value === category)?.key ?? 0,
          custodianId: filters?.custodians?.find(c => c.value === custodian)?.key ?? 0,
          locationId: filters?.locations?.find(c => c.value === location)?.key ?? 0,
          supplierId: filters?.suppliers?.find(c => c.value === supplier)?.key ?? 0,
          costCenterId: filters?.costCenters?.find(c => c.value === costCenter)?.key ?? 0
        };
        const res = await API.post(AssetPaths.Search(), data);
        setSearchResult(res.data)
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  };

  const handleClear = () => {
    setSearchValues({
      searchTerm: searchValues.searchTerm,
      category: '',
      custodian: '',
      location: '',
      status: '',
      condition: '',
      supplier: '',
      costCenter: '',
      createdFrom: null,
      createdTo: null,
      pageNumber: 1,
    });
    setShouldSearch(true);
  }

  const { searchTerm, createdFrom, createdTo, condition, category, costCenter, custodian, location, status, supplier } = searchValues;

  const selectedValues = {
    searchTerm,
    createdFrom: createdFrom ? createdFrom.toLocaleDateString() : '',
    createdTo: createdTo ? createdTo.toLocaleDateString() : '',
    condition,
    category,
    costCenter,
    custodian,
    location,
    status,
    supplier
  };

  const renderResults = () => {
    return isTableView ?
      <AssetResultTable assets={searchResult.results} /> :
      searchResult.results.map(r => <AssetResultCard asset={r} key={r.assetId} />)
  }

  return (
    <Container className="mb-3">
      <PageHeader pageTitle='Search and Filter' />
      <AlertComponent message={errorMessage} />
      <SelectedFilter clearAll={handleClear} values={selectedValues} unselectValue={handleValueUnSelected} />
      <InputGroup className="mb-3">
        <Button onClick={() => setOpen(true)} className="fw-bolder" variant="outline-success" id="button-filter">
          Advanced Search
        </Button>
        <Form.Control
          className="border border-1 border-success"
          size="lg"
          placeholder="Enter search term"
          aria-label="Search term"
          name="searchTerm"
          aria-describedby="search"
          value={searchValues.searchTerm ?? ''}
          onChange={handleInputChange}
        />
        {Boolean(searchValues.searchTerm) && <Button onClick={() => setSearchValues({ ...searchValues, searchTerm: '' })} className="fw-bolder" variant="outline-success" id="button-clear-search">
          <Icon icon={faClose} />
        </Button>}
        <Button disabled={!Boolean(searchValues.searchTerm)} onClick={handleSubmit} className="fw-bolder" variant="success" id="button-search">
          <Icon icon={faMagnifyingGlass} /> Search
        </Button>
        <Button onClick={handleViewChange} className="mx-1 fw-bolder col-1" variant="success" id="button-view">
          <Icon icon={isTableView ? faList : faTable} /> {isTableView? 'List' : 'Table'}
        </Button>
      </InputGroup>
      {
        loading ?
          <Loading /> : (
            searchResult.results.length ?
              renderResults() :
              <div>No result to display.</div>)
      }
      <Pagination {...{currentPage: searchResult.currentPage, pages: searchResult.pages }} paginate={handlePaginate} />
      <FiltersModal
        setDate={setDateRange}
        show={open}
        searchCriteria={searchValues}
        handleSubmit={handleSubmit}
        handleOnChange={handleInputChange}
        handleClear={handleClear}
        handleClose={() => setOpen(false)}
        filters={filters}
      />
    </Container>
  )
}

function Pagination(props: { currentPage: number, pages: number, paginate: (pageNumber: number) => any }) {
    const goBack = () => props.paginate(props.currentPage - 1)
    const goForward = () => props.paginate(props.currentPage + 1);

    if (props.pages === 0) {
        return null;
    }

    return (
        <div className="btn-group mx-2 mt-3 text-center" role="group" aria-label="First group">
            <button title="Go back to previous page" disabled={props.currentPage === 1} type="button" onClick={goBack} className="btn btn-outline-success btn-lg px-3"><span><FontAwesomeIcon size='lg' icon={faAngleLeft} /></span></button>
            <button disabled type="button" className="btn btn-outline-success btn-lg px-3 fw-bold">Page {props.currentPage} of {props.pages}</button>
            <button title="Go to next page" disabled={props.currentPage === props.pages} type="button" onClick={goForward} className="btn btn-outline-success btn-lg px-3"><span><FontAwesomeIcon size='lg' icon={faAngleRight} /></span></button>
        </div>
    )
}