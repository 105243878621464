import { NavButton } from "./Buttons";

export function PageHeader({ pageTitle, navPath}: { pageTitle: string, navPath?: string }) {
    return (
        <div  className="page-header my-3 p-3 fw-bold rounded border border-1 border-success text-success d-flex justify-content-between align-items-center">
            <h1 className="h3 mb-0">{pageTitle}</h1>
            <NavButton path={navPath ?? ''}/>
        </div>
    );
}
